@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  font-family: 'Montserrat', sans-serif;
  margin: 0px;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

#job_list_description {
  ul {
    list-style-image: url('https://theecode-assets.s3.us-west-1.amazonaws.com/Crm-Image/default-image/theecode-pli-imgs/list_arrow.svg');
  }
}

*,
::after,
::before {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

.textAlign_center {
  text-align: center;
}

.textAlign_right {
  text-align: right;
}

.textAlign_left {
  text-align: left;
}

.Display_Flex {
  display: flex !important;
}

.Display_InlineFlex {
  display: inline-flex !important;
}

.Display_Block {
  display: block !important;
}

.Display_inlineBlock {
  display: inline-block !important;
}

.DeskFlex_Mobileblock {
  display: flex;
}

.justify_content_End {
  justify-content: flex-end;
}

.justify_content_Center {
  justify-content: center;
}

.justify-content_Spacebetween {
  justify-content: space-between;
}

.align_item_Center {
  align-items: center;
}

.align_item_End {
  align-items: end;
}

.align_item_Stretch {
  align-items: stretch;
}

.flex_direction_Column {
  flex-direction: column;
}

.flex_wrap_Wrap {
  flex-wrap: wrap;
}

.gap_12 {
  gap: 12px;
}

.gap_16 {
  gap: 16px;
}

.gap_20 {
  gap: 20px;
}

.gap_30 {
  gap: 30px;
}

.width_100per {
  width: 100%;
}

.width_100per_import {
  width: 100% !important;
}

.width_24px {
  width: 24px !important;
}

.width_20px {
  width: 20px !important;
}

.width_18px {
  width: 18px !important;
}

.width_16px {
  width: 16px !important;
}

.width_10px {
  width: 10px !important;
}

.width_14px {
  width: 14px !important;
}

.height_100per {
  height: 100%;
}

.position_Relative {
  position: relative;
}

.zindex_999 {
  z-index: 999;
}

.font_Size14 {
  font-size: 14px;
}

.font_Size15 {
  font-size: 15px;
}

.font_Size16 {
  font-size: 16px;
}

.font_Size18 {
  font-size: 18px;
}

.font_Size20 {
  font-size: 20px;
}

.font_Size22 {
  font-size: 22px;
}

.font_Size24 {
  font-size: 24px;
}

.font_Size28 {
  font-size: 28px;
}

.font_Size30 {
  font-size: 30px;
}

.font_Size32 {
  font-size: 32px;
}

.font_Size34 {
  font-size: 34px;
}

.font_Size40 {
  font-size: 40px;
}

.font_Size44 {
  font-size: 44px;
}

.font_Size50 {
  font-size: 50px;
}

.font_Weight300 {
  font-weight: 300;
}

.font_Weight400 {
  font-weight: 400;
}

.font_Weight500 {
  font-weight: 500;
}

.font_Weight600 {
  font-weight: 600;
}

.font_Weight700 {
  font-weight: 700;
}

.font_StyleItalic {
  font-style: italic;
}

.cursor_Pointer {
  cursor: pointer;
}

.opacity_point3 {
  opacity: 0.3;
}

a.hrefLinkHover:hover {
  color: #FF4B00;
  text-decoration: unset !important;
}


.borderRadius_8px {
  border-radius: 8px;
}

.marginLeftRight_Auto {
  margin: 0px auto !important;
}

.marginLeft25 {
  margin-left: 25px !important;
}

.margin_Bottom8 {
  margin-bottom: 8px !important;
}

.margin_Bottom4 {
  margin-bottom: 4px !important;
}

.margin_Bottom12 {
  margin-bottom: 12px !important;
}

.margin_Bottom16 {
  margin-bottom: 16px !important;
}

.margin_Bottom24 {
  margin-bottom: 24px !important;
}

.margin_Bottom34 {
  margin-bottom: 34px !important;
}

.margin_Top34 {
  margin-top: 34px !important;
}

.margin_Top24 {
  margin-top: 24px !important;
}

.margin_Top12 {
  margin-top: 12px !important;
}

.margin_Bottom50 {
  margin-bottom: 50px !important;
}

.margin_Right12 {
  margin-right: 12px;
}

.margin_LeftRight16 {
  margin: 0px 16px;
}

.padding_16px {
  padding: 16px;
}

.padding_LeftRight34_TopBottom24 {
  padding: 24px 34px;
}

.padding_Top12 {
  padding-top: 12px;
}

.padding_Top20 {
  padding-top: 20px;
}

.padding_Right20 {
  padding-right: 20px;
}

.padding_Bottom16 {
  padding-bottom: 16px;
}

.padding_Bottom12 {
  padding-bottom: 12px;
}

.padding_TopBottom80 {
  padding: 80px 0px;
}

.paddingLeftright_12 {
  padding: 0px 12px;
}

.paddingLeft24 {
  padding-left: 24px !important;
}

.empty_UnorderList {
  margin: 0px;
  list-style: none;
  padding: 0px;
}

.textDecoration_Underline {
  text-decoration: underline !important;
}

.textDecoration_Underline_unset {
  text-decoration: unset !important;
}

.columnCount_two {
  column-count: 2;
}

.noColor_btn {
  background: transparent;
  border: 1px solid transparent;
  padding: 0px;
  outline: unset !important;
}


.Mainbutton {
  padding: 0px 24px;
  height: 44px;
  border-radius: 7px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  position: relative;
  -webkit-transition: .5s;
  transition: .5s;
  outline: unset;
  display: inline-flex !important;
  align-items: center;
  border: 1px solid transparent;
  text-decoration: unset;
  -webkit-transition: .5s;
  transition: .5s;
  pointer-events: all;
  cursor: pointer;
  z-index: 0;
}

.Mainbutton:hover {
  text-decoration: unset;
}

.smallMainbutton {
  padding: 0px 24px;
  height: 36px;
  border-radius: 7px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  position: relative;
  outline: unset;
  display: inline-flex !important;
  align-items: center;
  border: 1px solid transparent;
  text-decoration: unset;
  -webkit-transition: .5s;
  transition: .5s;
  pointer-events: all;
  cursor: pointer;
  z-index: 0;
}

.infiniteNightBtn_clr {
  border: 1px solid #080B36;
  color: #FFFFFF !important;
}

.infiniteNightBtn_clr:hover {
  color: #080B36 !important;
  border: 1px solid #080B36;
}

.infiniteNightBtn_clr:hover::before {
  -webkit-transform: scale(0);
  transform: scale(0);
}

.infiniteNightBtn_clr::before {
  position: absolute;
  content: '';
  background: #080B36;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  top: 0;
  left: 0;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: .5s;
  transition: .5s;
  z-index: -1;
}

















.infiniteNightHoverBtn_clr {
  background-color: transparent;
  border: 2px solid #080B36;
  color: #080B36;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  -webkit-appearance: none;
  appearance: none;
  outline: none !important;
  transition: all 0.5s linear;
  overflow: hidden;
  z-index: 1;
}

.infiniteNightHoverBtn_clr:hover {
  color: #FFFFFF;
}

.infiniteNightHoverBtn_clr:before {
  position: absolute;
  content: '';
  background-color: #16115f;
  width: 120%;
  height: 0;
  padding-bottom: 120%;
  top: -110%;
  left: -10%;
  border-radius: 50%;
  transform: translate3d(0, 68%, 0) scale3d(0, 0, 0);
  z-index: -1;
}

.infiniteNightHoverBtn_clr:hover::before {
  transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
}

.infiniteNightHoverBtn_clr:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  background-color: #080B36;
  transform: translate3d(0, -100%, 0);
  transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
  z-index: -1;
}

.infiniteNightHoverBtn_clr:hover::after {
  transform: translate3d(0, 0, 0);
  transition-duration: 0.05s;
  transition-delay: 0.4s;
  transition-timing-function: linear;
}


.envGreen_bg {
  background: #006C4B;
}

.cerebralGrey_bottomborder {
  border-bottom: 1px solid #CCC;
}

.infiniteNight_bg {
  background: #080B36;
}

.environmentalGreenBtn_clr {
  /* background-color: #006C4B; */
  border: 1px solid #006C4B;
  color: #FFFFFF;
}

/* .environmentalGreenBtn_clr:hover {
  background-color: #006c4bd1;
  border: 1px solid #006c4bd1;
} */

.environmentalGreenBtn_clr {
  border: 1px solid #006C4B;
  color: #FFFFFF !important;
}

.environmentalGreenBtn_clr:hover {
  color: #006C4B !important;
  border: 1px solid #006C4B;
}

.environmentalGreenBtn_clr:hover::before {
  -webkit-transform: scale(0);
  transform: scale(0);
}

.environmentalGreenBtn_clr::before {
  position: absolute;
  content: '';
  background: #006C4B;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  top: 0;
  left: 0;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: .5s;
  transition: .5s;
  z-index: -1;
}




.environmentalGreenHoverBtn_clr {
  background-color: transparent;
  border: 2px solid #006C4B;
  color: #006C4B;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  -webkit-appearance: none;
  appearance: none;
  outline: none !important;
  transition: all 0.5s linear;
  overflow: hidden;
  z-index: 1;
}

.environmentalGreenHoverBtn_clr:hover {
  color: #FFFFFF;
}

.environmentalGreenHoverBtn_clr:before {
  position: absolute;
  content: '';
  background-color: #006C4B;
  width: 120%;
  height: 0;
  padding-bottom: 120%;
  top: -110%;
  left: -10%;
  border-radius: 50%;
  transform: translate3d(0, 68%, 0) scale3d(0, 0, 0);
  z-index: -1;
}

.environmentalGreenHoverBtn_clr:hover::before {
  transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
}

.environmentalGreenHoverBtn_clr:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  background-color: #006C4B;
  transform: translate3d(0, -100%, 0);
  transition: transform 0.4s cubic-bezier(0.1, 0, 0.3, 1);
  z-index: -1;
}

.environmentalGreenHoverBtn_clr:hover::after {
  transform: translate3d(0, 0, 0);
  transition-duration: 0.05s;
  transition-delay: 0.4s;
  transition-timing-function: linear;
}



.whiteFilter {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(6%) hue-rotate(190deg) brightness(104%) contrast(104%);
}


.phaserBeamBtn_clr {
  /* background-color: #FF4B00; */
  border: 1px solid #FF4B00;
  color: #FFFFFF !important;
}





.phaserBeamBtn_clr {
  border: 1px solid #FF4B00;
  color: #FFFFFF !important;
}

.phaserBeamBtn_clr:hover {
  color: #FF4B00 !important;
  border: 1px solid #FF4B00;
}

.phaserBeamBtn_clr:hover::before {
  -webkit-transform: scale(0);
  transform: scale(0);
}

.phaserBeamBtn_clr::before {
  position: absolute;
  content: '';
  background: #FF4B00;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  top: 0;
  left: 0;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: .5s;
  transition: .5s;
  z-index: -1;
}







.extraordinary_abundance_bottomborder {
  border-bottom: 1px solid #E6E6E6;
}



.whiteInput {
  border-radius: 8px;
  background: #FFFFFF;
  border: 1px solid #FFFFFF;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  width: 100%;
  height: 44px;
  padding: 0px 16px;
  outline: unset;
}

.whiteInput::placeholder {
  color: #080B36;

}

.whiteSelectInput {
  border-radius: 8px;
  border: 1px solid #FFFFFF;
  height: 44px;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
  padding: 0px 16px;
  outline: unset;
  width: 100%;
  background: #FFFFFF url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/dropdown_arrow.svg");
  background-size: 13px;
  background-position: calc(100% - .75rem) !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  background-repeat: no-repeat;
  appearance: none !important;
}

/* Color Text */
.infiniteNightText {
  line-height: 1.5;
  margin: 0px;
  color: #080B36;
}

.steelWoolText {
  line-height: 1.5;
  margin: 0px;
  color: #777777;
}

.shiningSilverText {
  line-height: 1.5;
  margin: 0px;
  color: #C8C8C8;
}

.whiteColor_Text {
  color: #FFFFFF;
  line-height: 1.5;
  margin: 0px;
}

.whiteColor_50per {
  color: rgba(255, 255, 255, 0.50);
  line-height: 1.5;
  margin: 0px;
}

.phaserBeamText {
  color: #FF4B00;
  line-height: 1.5;
  margin: 0px;
}

.neroText {
  color: #252525;
  line-height: 1.5;
  margin: 0px;
}

.aerospaceOrangeText {
  color: #FF4E00;
  line-height: 1.5;
  margin: 0px;
}

.shadowdancerText {
  color: #0A0F59;
  line-height: 1.5;
  margin: 0px;
}

.environmentalGreenText {
  color: #006C4B;
  line-height: 1.5;
  margin: 0px;
}



@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

@media (min-width: 1400px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1320px;
  }
}

.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  z-index: 1020;
  box-shadow: 0 0 10px rgba(0, 0, 0, .15);
}

/* Header Code Start */
.MobileToggle {
  display: none;
}

.advantageBig_Mobile {
  display: none;
}

.topHeader {
  background-color: #020422;
  padding: 12px 0px;
  position: relative;
  z-index: 99999;
}

.topHeader .container>div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

ul.topHeaderMenulist {
  display: flex;
  align-items: center;
  margin: 0px;
  list-style: none;
  padding: 0px;
}

ul.topHeaderMenulist li a {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5;
  color: #FFFFFF;
  margin-right: 32px;
  text-decoration: none !important;
  gap: 4px;
  position: relative;
  -webkit-transition: .5s;
  transition: .5s;
  display: block;
}

ul.topHeaderMenulist li:last-child a {
  margin-right: 0px;
}

ul.topHeaderMenulist li a:hover {
  color: #FF4B00;
}

.siteHeader_wrapper {
  background: #FFFFFF;
  /* border-bottom: 1px solid #d4d1d1; */
}

.siteHeader_wrapper .pli_brandLogo {
  padding: 0px;
  margin: 0px;
}

.siteHeader_wrapper .pli_brandLogo img {
  width: 100px;
}

.siteHeader_wrapper nav {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  padding: 0px !important;
}

.siteHeader_wrapper ul.navbar-nav {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.siteHeader_wrapper ul.navbar-nav li a {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  color: #252525;
  margin-right: 24px;
  /* padding: 16px 8px; */
  padding: 28px 8px;
  text-decoration: none !important;
  gap: 4px;
  position: relative;
  -webkit-transition: .5s;
  transition: .5s;
  cursor: pointer;
}

.siteHeader_wrapper ul.navbar-nav li:last-child a {
  margin-right: 0px;
}

.siteHeader_wrapper ul.navbar-nav li a:hover,
.siteHeader_wrapper ul.navbar-nav li a.active {
  color: #FF4B00;
}

.siteHeader_wrapper ul.navbar-nav li a span.leaf_icon {
  position: relative;
}

.Sustanability_text {
  color: #00D26A !important;
}

.siteHeader_wrapper ul.navbar-nav li a span.leaf_icon::after {
  content: "";
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/leaf_icon.svg");
  display: block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  position: absolute;
  left: 50%;
  bottom: 10px;
  background-size: 14px;
  transform: translateX(-50%);
  background-position: center;
}

.siteHeader_wrapper ul.navbar-nav li.nav-item.dropdown {
  display: inline-block;
}

.siteHeader_wrapper ul.navbar-nav li.nav-item.dropdown:hover ul.dropdown-menu {
  display: block;
}

.siteHeader_wrapper ul.navbar-nav .dropdown-toggle::after {
  margin-left: 8px;
}

.siteHeader_wrapper ul.navbar-nav li.nav-item.dropdown:hover .dropdown-toggle::after {
  transform: rotate(180deg);
}

/* .siteHeader_wrapper ul.navbar-nav li.dropdown ul.dropdown-menu li a.dropdown-item:focus,
.siteHeader_wrapper ul.navbar-nav li.dropdown ul.dropdown-menu li a.dropdown-item:hover {
  background-color: transparent;
} */

.siteHeader_wrapper ul.navbar-nav li.dropdown ul.dropdown-menu {
  border: unset;
  display: none;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
  border-top: 2px solid #FF4B00;
  border-radius: 2px;
  padding: 0px;
  margin: 0px;
  overflow: hidden;
}

.siteHeader_wrapper ul.navbar-nav li.dropdown ul.dropdown-menu li a {
  margin: 0px !important;
  padding: 8px 20px;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
  color: #252525;
  background: #FFF;
  display: flex;
  align-items: center;
  gap: 12px;
}

.siteHeader_wrapper ul.navbar-nav li.dropdown ul.dropdown-menu li a:hover {
  /* padding-left: 16px !important; */
  transform: translateX(12px);
  transition: all 0.5s ease;
  color: #FF4B00;
  text-shadow: unset;
}

.siteHeader_wrapper ul.navbar-nav li.dropdown ul.dropdown-menu li a:focus span,
.siteHeader_wrapper ul.navbar-nav li.dropdown ul.dropdown-menu li a:focus-within span {
  text-shadow: unset;
}

.siteHeader_wrapper ul.navbar-nav li.dropdown ul.dropdown-menu li a:focus,
.siteHeader_wrapper ul.navbar-nav li.dropdown ul.dropdown-menu li a:focus-within {
  text-shadow: unset;
}

/* Header Code end */
.notification_panel {
  background-color: #020422;
  padding: 12px 0px;
  width: 100%;
  z-index: 999;
  position: relative;
}

/* banner Code  start */
.homeBnr_wrapper {
  padding: 80px 0px 200px;
  position: relative;
}

.textureArrow {
  position: relative;
}

.textureArrow::before {
  content: "";
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/ipadTexture_arrow.svg");
  width: 100%;
  height: 201px;
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  left: 70px;
  background-size: contain;
  position: absolute;
  bottom: 0px;
  /* transform: translate(110px , 74px); */
  position: absolute;
  top: 0px;
  z-index: -12;
}

.ipadFrame {
  position: relative;
  /* z-index: 999; */
  z-index: -12;
}

.ipadFrame img {
  object-fit: cover;
  width: 100%;
  /* height: 201px; */
  display: block;
  position: absolute;
  left: 30px;
  top: 85px;
  /* transform: translate(-24.5%, 0px); */
  transform: scale(1.2);
  object-position: right;
  z-index: -1;
}

.ipadFrame::after {
  content: "";
  background-image: linear-gradient(180deg, #FFF 32.81%, #83C2FD 100%);
  filter: blur(74.8611068725586px);
  height: 120px;
  position: absolute;
  top: 70px;
  width: 100%;
  z-index: -1;
}

.homeBnr_wrapper .bannerContent h1 {
  font-size: 50px;
  line-height: 1.5;
  font-weight: 700;
  margin: 0px;
  color: #080B36;
  /* min-height: 225px; */
}

.homeBnr_wrapper .bannerContent h2 {
  font-size: 40px;
  line-height: 1.5;
  font-weight: 700;
  margin: 0px;
  color: #080B36;
}

.homeBnr_wrapper .bannerContent p {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  margin: 0px;
  color: #777777;
}

.bnr_Linegrp {
  position: relative;
}

.bnr_Linegrp::after {
  content: "";
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/bnr_Linegrp.png");
  /* width: 484px;
  height: 419px; */
  width: 392px;
  height: 188px;
  display: block;
  position: absolute;
  right: 0px;
  top: 0px;
  /* top: -130px; */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.bnr_groupCard {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  bottom: 0px;
}

.bnr_groupCard .cardOne,
.bnr_groupCard .cardTwo {
  width: 180px;
}

/* .bnr_groupCard>div:first-child {
  position: relative;
  z-index: 999;
} */
.bnr_groupCard img.cardTwo {
  transform: rotate(15deg) translate(-13px, 90px);
}

.cardOnetopFix {
  position: relative;
  /* z-index: 1034; */
  z-index: -1;
}

.dash_impact {
  content: "";
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/cardOnetopFix.png") center top / contain no-repeat;
  width: 74px;
  height: 38px;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  background-size: contain;
  opacity: 0;
  scale: 0;
}

.sectionheader {
  padding-bottom: 50px;
  width: 70%;
  margin: auto;
}

.sectionheader h2 {
  color: #080B36;
  font-size: 32px;
  font-weight: 600;
  line-height: 1.5;
  margin: 0px;
}

.pli_capabilities {
  position: relative;
  /* z-index: 999; */
  padding: 80px 0px 40px;
}

.pli_capabilities::before {
  content: "";
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/wave_capabilities.svg");
  background-size: cover;
  background-position: end;
  background-repeat: no-repeat;
  width: 100%;
  height: 579px;
  display: block;
  position: absolute;
  bottom: 0px;
  z-index: -1;
}

.capabilitiesRow {
  display: flex;
  gap: 20px;
  padding-left: 110px;
  justify-content: flex-start;
  /* flex-wrap: wrap; */
  width: 100%;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  /* white-space: nowrap; */
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow-x: hidden;
  z-index: -1;
}

.capabilitiesItem {
  background: #CFE3EC;
  /* flex: 1 1 calc(335px - 20px); */
  min-width: 335px;
  padding: 16px;
  /* max-width: calc(33.333% - 20px); */
  min-height: 400px;
  display: flex;
  flex: 1;
  /* align-items: center; */
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  height: 100%;
  scroll-snap-align: start;
  flex-shrink: 0;
  text-decoration: unset;
}

.capabilitiesItem:hover {
  text-decoration: unset;
  opacity: .72;
}

.capabilitiesRow::-webkit-scrollbar {
  width: 10px;
  /* Set the scrollbar width */
  height: 6px;
}

.capabilitiesRow::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Set the thumb color */
}

.capabilitiesItem:nth-child(odd) {
  border-radius: 0px 80px 80px 80px;
}

.capabilitiesItem:nth-child(odd) .capabilities_content {
  border-radius: 0px 60px 60px 60px;

}

.capabilitiesItem:nth-child(even) {
  border-radius: 80px 0px 80px 80px;
  margin-top: 80px;
}

.capabilitiesItem:nth-child(even) .capabilities_content {
  border-radius: 60px 0px 60px 60px;
}

.capabilities_img {
  margin-bottom: 24px;
}

.capabilities_img img {
  /* width: 200px; */
  height: 200px;
  /* width: 100%; */
}

.capabilities_content {
  background: #FFF;
  padding: 34px;
}

.pli_Advantages {
  padding: 80px 0px;
}

.card_advantage {
  border-radius: 10px;
  border-top: 2px solid #080B36;
  background: #FFF;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.15);
  min-width: 285px;
  /* min-height: 206px; */

  /* height: 100%; */
  padding: 16px;
  display: flex;
  align-items: center;
  /* flex-direction: column; */
  gap: 16px;
  justify-content: center;
  opacity: 0;
}

/* .card_advantage p{
  text-align: center;
} */
.card_advantage img {
  text-align: center;
}

.card_advantage p {
  font-size: 16px;
}

.advantageBig_Circle {
  border: 3px dashed #080B36;
  width: 480px;
  height: 480px;
  border-radius: 50%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  place-content: center;
  position: relative;
}

.advantageBig_Circle::before {
  content: "";
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/zoomCircle_img.svg");
  width: 280px;
  height: 280px;
  border-radius: 50%;
  display: block;
  animation: zoom-in-zoom-out 2s ease-out infinite;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;

}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.5, 1.5);
  }

  100% {
    transform: scale(1, 1);
  }
}

.advantage_groupCard {
  /* display: flex;
  align-items: center; */
  /* position: relative;
  z-index: 999; */
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}

.advantage_groupCard>div:first-child img {
  visibility: hidden;
}

.advantage_groupCard .adv_groupCard_one,
.advantage_groupCard .adv_groupCard_two {
  width: 140px;
}

.advantage_groupCard>div:first-child {
  position: absolute;
  z-index: 999;
}

.advantage_groupCard img.adv_groupCard_one {
  transform: rotate(18deg) translate(30px, -40px);
}

.achievements_sofar {
  position: relative;
  margin-bottom: 50px;
  z-index: -2;
}

.achievements_sofar::before {
  content: "";
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/box-gradient-bg.jpg");
  height: 662px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  position: absolute;
}

.achievementsSofar_content {
  position: relative;
  z-index: 999;
  padding: 80px 0px 0px;
}

.achievementRectangle {
  border-radius: 37px;
  background: #FFF;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.20);
  padding: 16px 0px 16px 16px;
}

.customChartFrame {
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/customChart.svg");
  height: 580px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  /* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.20); */
  /* border-radius: 20px; */
  position: relative;
}

/* .customChartFrame::before{
  content: "";
  position: absolute;
  background: #080B36;
  width: 60px;
  height: 100%;
  display: block;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
} */

.industries_we_specialize {
  background: #F5F5F5;
  padding: 40px 0px 80px;
  margin-bottom: 160px;
}

.equalHeight {
  height: 520px;
  padding: 5px;
}

.Industries_wrapper {
  display: flex;
  gap: 20px;
  /* justify-content: flex-start; */
  /* width: 100%; */
  /* overflow-x: auto; */
  /* overflow-y: hidden; */
  /* scroll-snap-type: x mandatory; */
  /* scrollbar-width: none; */
  /* -ms-overflow-style: none; */
  /* overflow-x: hidden; */

}

/* .Industries_wrapper>div { */
/* width: 100%; */
/* min-width: 100%; */
/* max-width: 100%; */
/* flex: 1; */
/* margin-right:-20px; */
/* box-sizing: border-box; */
/* height: 100%; */
/* scroll-snap-align: start; */
/* flex-shrink: 0; */
/* } */

ul.carousel_indicator {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  list-style: none;
  padding: 0px;
  margin: 0px;
  gap: 16px;
}

ul.carousel_indicator li {
  position: relative;
  width: 100%;
}

ul.carousel_indicator li a {
  display: block;
  padding-top: 18px;
  color: #777777;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
  text-decoration: unset;
  text-wrap: nowrap;
}

ul.carousel_indicator li a:before {
  content: "";
  height: 4px;
  background: #D9D9D9;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

ul.carousel_indicator li a:after {
  content: "";
  height: 4px;
  background: #FF4E00;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 0px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  border-radius: 999px;
  display: block;
}

ul.carousel_indicator li a:hover,
ul.carousel_indicator li a.active {
  color: #080B36 !important;
  text-decoration: unset;
}

ul.carousel_indicator li a:hover::after,
ul.carousel_indicator li a.active:after {
  width: 100%;
}

ul.carousel_indicator li a::first-letter {
  font-size: 24px;
}

ul.carousel_indicator li .active:after {
  background: #FF4B00;
  width: 100%;
}


/* industries_we_specialize caroucel */
.industries_we_specialize .owl-dots {
  display: none;
}

.industries_we_specialize .owl-theme .owl-nav {
  margin: 0px;
  text-align: left;
  position: absolute;
  bottom: 20px;
  right: 0;
}

.industries_we_specialize .owl-theme .owl-nav>button:first-child {
  margin-right: 16px !important;
}

.industries_we_specialize #sync2 h5 {
  display: block;
  padding-top: 24px;
  color: #777777;
  font-size: 13px;
  line-height: 1.5;
  font-weight: 500;
  text-decoration: unset;
  text-wrap: nowrap;
  margin: 0px;
}

.industries_we_specialize #sync2 h5::first-letter {
  font-size: 24px;
}

.industries_we_specialize .owl-stage-outer .owl-item.active h5:before {
  content: "";
  height: 4px;
  position: absolute;
  left: 0px;
  bottom: 40px;
  width: 100%;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  background: #D9D9D9;
  border-radius: 999px;
  display: block;
}


.industries_we_specialize .owl-stage-outer .owl-item.active h5:after {
  content: "";
  height: 4px;
  background: #FF4B00;
  position: absolute;
  left: 0px;
  bottom: 40px;
  width: 0px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
  border-radius: 999px;
  display: block;
}

.industries_we_specialize .owl-stage-outer .owl-item.active h5:hover:after,
.industries_we_specialize .owl-stage-outer .owl-item.active.current h5:after {
  width: 100%;
}

.industries_we_specialize .owl-stage-outer .owl-item.active h5:hover {
  color: #080B36;
  background-color: unset !important;
}

.customSlide {
  padding-left: 20px;
  margin-right: -20px;
}

#seventh {
  margin-right: -20px !important;
}

.industSpecialize_videoFrame {
  position: relative;
  right: 30px;
  top: 30px;
}

.industSpecialize_videoFrame video {
  object-fit: cover;
  z-index: 999;
  position: relative;
  margin-left: auto;
  display: block;
  width: 100%;
  height: 406px;
}

.industSpecialize_videoFrame::after {
  content: "";
  background: rgba(10, 10, 10, 0.30);
  width: 100%;
  height: 100%;
  position: absolute;
  top: -24px;
  right: -24px;
}

.homeIndust_img {
  position: relative;
}

.homeIndust_img img {
  width: 100%;
  height: 450px;
  object-fit: contain;
}

.connectedWithForm {
  padding-left: 60px;
}

.connectedWith_pli {
  background: #080B36;
  padding: 80px 0px 50px;
  position: relative;
  margin-bottom: 80px;
}

.connectedWith_leftImg {
  position: relative;
  padding-left: 0px;
}

.connectedWith_leftImg {
  /* content: ""; */
  /* background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/connected_pli.png"); */
  /* background-repeat: no-repeat;
  background-position: left top;
  background-size: cover;
 */
  /* height: 594px; */
  width: 100%;
  height: 100%;
  position: absolute !important;
  display: block;
  left: 0px;
  top: -90px;
}


.marshalBlueInput {
  border-radius: 5px;
  background: #3F3F55;
  border: 1px solid #3F3F55;
  width: 100%;
  height: 40px;
  padding: 0px 16px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: #FFF;
  outline: unset;
}

.whiteSmokeInput {
  border-radius: 5px;
  background: #F5F5F5;
  border: 1px solid #F5F5F5;
  height: 44px;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
  padding: 0px 16px;
  outline: unset;
  width: 100%;
}

.whiteSmokeInput::placeholder {
  color: #777;
}

.whiteSmokeSelectInput {
  border-radius: 5px;
  border: 1px solid #F5F5F5;
  height: 44px;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
  padding: 0px 16px;
  outline: unset;
  width: 100%;
  background: #F5F5F5 url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/dropdown_arrow.svg");
  background-size: 13px;
  background-position: calc(100% - .75rem) !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  background-repeat: no-repeat;
  appearance: none !important;
}

.whiteSmokeTextarea {
  border-radius: 5px;
  background: #F5F5F5;
  border: 1px solid #F5F5F5;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
  padding: 16px;
  outline: unset;
  width: 100%;
}


.industSpecialize_Card {
  position: absolute;
  bottom: -30px;
  left: -50px;
  z-index: 999;
}

.industSpecialize_Card img {
  width: 150px;
  object-fit: cover;
}





















/* Input checkbox */

.inputCheckbox {
  display: block;
}

.inputCheckbox input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
}

.inputCheckbox label {
  position: relative;
  left: 0px;
  padding: 0px;
  margin: 0px;
  color: #FFF;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5;
  text-transform: unset;
  cursor: pointer;
}

.inputCheckbox label:before {
  content: "";
  border: 1px solid #F7F7F8;
  background-color: #F7F7F8;
  border-radius: 3px;
  width: 20px;
  height: 20px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  margin-right: 11px;
}



.inputCheckbox input:checked+label:after {
  content: "";
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/tick_icon.svg");
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  top: 12px;
  left: 10px;
  width: 20px;
  height: 20px;
  transform: translate(-50%, -50%);
  bottom: 0px;
  background-position: center;
  background-size: 16px;
}

.inputCheckbox input[type="checkbox"]:checked+label {
  color: #FFFFFF;
}

.inputCheckbox input[type="checkbox"]:checked+label:before {
  transform: scale(1);
  background-color: #FF4B00;
  border-color: #FF4B00;
}




/* radio button start */

.radio_method {
  align-items: center;
  display: flex;
}

.radio_method label {
  margin-bottom: 0px;
  color: #777777;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.radio_method input[type="radio"]+label {
  position: relative;
}

.radio_method input[type="radio"]+label::before {
  /* Outer Circle of radio button */
  background: #F5F5F5;
  content: " ";
  display: inline-block;
  margin-right: 9px;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  transition: border 0.15s ease-in-out;
  position: relative;
  top: 0px;
}

.radio_method input[type="radio"]+label::after {
  /* Inner Circle of radio button */
  border: 0px solid transparent;
  content: " ";
  display: inline-block;
  width: 14px;
  height: 14px;
  position: absolute;
  left: 3px;
  transition: border 0.15s ease-in-out;
  /* border: 1px solid #f46801;
  background-color: #f46801; */
  border-radius: 5px;

}

.radio_method input[type="radio"] {
  display: none;
}

/* When button is active */
.radio_method input[type="radio"]:checked+label::after {
  background-repeat: no-repeat;
  background-size: 10px;
  border: 1px solid #FF4B00;
  background-color: #FF4B00;
  width: 14px;
  height: 14px;
}

.radio_method input[type="radio"]:checked+label {
  color: #000;
}

/* radio button end */


/* Industries We Serve */

.blogSection {
  position: relative;
}

.blogSection::before {
  content: "";
  position: absolute;
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/solitudebox_gradient.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  /* width: 880px; */
  width: 100%;
  height: 100%;
}

.blogSection .owl-dots {
  display: none;
}

.blogCard {
  position: relative;
}

.blogCard_Frame .blogCard_img img {
  min-height: 380px;
  max-height: 380px;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.blogCard_Frame {
  position: relative;
}

.blogCard .blogCard_Frame::before {
  /* border-radius: 0px 0px 15px 15px; */
  content: "";
  position: absolute;
  /* // top: 310px; */
  top: 200px;
  right: 0;
  bottom: 0;
  left: 0;
  /* // background: var(--deepblack); */
  background-image: linear-gradient(to top, #000, transparent);

  /* // opacity: 0.2; */
  transition: 0.5s;
}

.blogCard:hover .blogCard_Frame:before {
  content: unset !important;
}

.blogCard_caption {
  width: 100%;
  z-index: 999;
  position: absolute;
  bottom: 30px;
  padding: 0px 24px;
  transition: 0.5s;
}

.blogCard_caption img {
  display: none !important;
  width: 80px !important;

}

.blogCard:hover .blogCard_Frame .blogCard_caption img {
  display: block !important;
}

.blogCard:hover .blogCard_Frame .blogCard_caption {
  top: 0px;
  bottom: 0px;
  left: 0;
  right: 0;
  text-align: center;
  transition: 0.5s;
  /* transform: translate(-50% , -50%); */
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
}

.blogCard:hover .blogCard_Frame::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #000;
  opacity: 0.73;
  /* border-radius: 12px; */
  transition: 0.5s;
}

.blogCard_caption h3 {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 0px;
  color: #FFFFFF;
}

.blogCard:hover .blogCard_caption {
  -webkit-animation: tracking-in-expand-fwd-bottom 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
  animation: tracking-in-expand-fwd-bottom 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

@-webkit-keyframes tracking-in-expand-fwd-bottom {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-700px) translateY(500px);
    transform: translateZ(-700px) translateY(500px);
    opacity: 0;
  }

  40% {
    opacity: 0.6;
  }

  100% {
    -webkit-transform: translateZ(0) translateY(0);
    transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}

@keyframes tracking-in-expand-fwd-bottom {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-700px) translateY(500px);
    transform: translateZ(-700px) translateY(500px);
    opacity: 0;
  }

  40% {
    opacity: 0.6;
  }

  100% {
    -webkit-transform: translateZ(0) translateY(0);
    transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}


.blogCard_caption p {
  font-size: 14px;
  font-weight: 400;
  margin: 0px;
  color: #FFFFFF;
  line-height: 1.5;
}



.blogSection .owl-prev,
.blogSection .owl-next {
  display: block !important;
}

.blogSection .owl-theme .owl-nav {
  margin: 0px;
  position: absolute;
  top: -40%;
  right: 6%;
  display: flex;
  gap: 20px;
}

.blogLeftSpace {
  padding: 0px 0px 50px 90px;
}

.owl-prev,
.owl-next {
  width: 45px;
  height: 45px;
  border-radius: 50% !important;
}

.owl-theme .owl-nav [class*="owl-"] {
  border-radius: 50%;
  background: unset;
}

.owl-next img {
  filter: brightness(0) saturate(100%) invert(46%) sepia(90%) saturate(4934%) hue-rotate(1deg) brightness(102%) contrast(107%);
}

.owl-next {
  background-color: #FF4B00 !important;
}

.owl-next:hover {
  background-color: #ff4b009c !important;
}

.owl-next img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7479%) hue-rotate(70deg) brightness(99%) contrast(107%);
}


.owl-prev {
  background-color: #080B36 !important;
}

.owl-prev:hover {
  background-color: #080b36a6 !important;

}

.owl-prev img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7479%) hue-rotate(70deg) brightness(99%) contrast(107%);
}

.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel button.owl-dot {
  margin: 0px;
  outline: unset !important;
}

.blog-date {
  position: absolute;
  background: #FFFFFF;
  width: 60px;
  text-align: center;
  top: 0;
  right: 12px;
  padding: 6px;
  -webkit-transition: .5s;
  transition: .5s;
}


.plims_customized {
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/darkKnight_bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  padding: 80px 0px;
  margin: 80px 0px 50px;
  height: auto;
  overflow: hidden;
}

.siteFooter_wrapper {
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/footer_bg.png");
  padding: 80px 0px 40px;
  background-repeat: no-repeat;
  background-size: cover;
}


ul.socialMedia_icon {
  display: flex;
  align-items: center;
  /* gap: 20px; */
  padding: 0px;
  list-style: none;
  margin: 0px;
}

ul.socialMedia_icon li a {
  width: 36px;
  height: 36px;
  /* border-radius: 10px; */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FF4B00;
}

ul.socialMedia_icon li a:hover {
  background-color: #ff4b00d1;
}



/* ul.socialMedia_icon li:nth-child(odd) a{
  margin-top: 20px;
} */
ul.siteFooterMenulist {
  padding: 0px;
  list-style: none;
  margin: 0px;
}

ul.siteFooterMenulist li {
  margin-bottom: 12px;
}

ul.siteFooterMenulist li a {
  display: flex;
  align-items: center;
  gap: 16px;
  transition: all 0.5s ease;
}

ul.siteFooterMenulist li a:hover {
  color: #FF4B00;
  text-decoration: unset;
  padding-left: 10px;

}

ul.siteFooterMenulist li:last-child {
  margin-bottom: 0px;
}

.square {
  z-index: 999;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.siteVideoSec {
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/videoFrame.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  /* height: 530px; */
  height: auto;
  margin: 80px 0px;
  position: relative;
  z-index: 999;
}

.siteVideoSec iframe {
  border-radius: 30px;
}

.StepFrame {
  display: flex;
  padding: 5px 16px 24px 0px;
}

.itemCol {
  flex: 1 1;
}

.StepFrame .itemCol:nth-child(2) {
  text-align: center;
}

.StepFrame .itemCol:nth-child(3)>div {
  padding-top: 50px;
  width: 170px;
}

.StepFrame .itemCol:nth-child(4) {
  text-align: center;
}

.StepFrame .itemCol:nth-child(4)>div {
  padding-top: 170px;
  width: 200px;
}

.StepFrame .itemCol:nth-child(5) {
  text-align: center;

}

.StepFrame .itemCol:nth-child(5)>div {
  padding-top: 245px;
  width: 200px;
}
















.hero__btn {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* transform: rotate(-45deg); */
}

.hero__btn .circle-btn {
  width: 120px;
  height: 120px;
}

.circle-btn {
  height: 180px;
  width: 180px;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.circle-btn .image-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  animation: rotate 20s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.artboardAnimation {

  position: absolute;
  /* -webkit-animation: translatey 10s infinite linear; */
  /* animation: translatey 10s infinite linear; */
  z-index: 999;
}

.artboardAnimation::before {
  content: "";
  background: url(https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/Artboard_ball.svg);
  position: absolute;
  /* -webkit-animation: translatey 5s infinite linear; */
  /* animation: translatey 5s infinite linear; */
  display: block;
  width: 72px;
  height: 72px;
  background-repeat: no-repeat;
  background-size: cover;
  left: 25px;
  margin-left: auto;
  bottom: 0px;
}

@keyframes translatey {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }

  50% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px)
  }

  10% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px)
  }
}



.blackCircle_Animation {
  position: relative;
  -webkit-animation: translatey 10s infinite linear;
  animation: translatey 10s infinite linear;
  top: 180px;
}

.blackCircle_Animation::before {
  content: "";
  background: url(https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/black_circle_ani.svg);
  position: absolute;
  -webkit-animation: translatey 5s infinite linear;
  animation: translatey 5s infinite linear;
  display: block;
  width: 72px;
  height: 200px;
  background-repeat: no-repeat;
  background-size: cover;
  right: 0px;
  margin-left: auto;
}

@keyframes translatey {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }

  50% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px)
  }

  10% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px)
  }
}


.arrowPointingMap {
  position: relative;
}

.arrowPointingMap::after {
  content: "";
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/arrowPointingMap.svg");
  display: block;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top;
  position: absolute;
  width: 102px;
  height: 100px;
  bottom: 30px;
  left: 60%;
  z-index: 999;
}

.siteFooterLogo img {
  width: 90px;
}

.rotateCard_animation {
  overflow: auto;
}

.rotateCard_animation>div img {
  width: 120px;
}



/* Define the animation */
@keyframes scroll {
  0% {
    transform: translateY(-10%);
  }

  100% {
    transform: translateY(-80%);
  }
}

@keyframes scrollOne {
  0% {
    transform: translateY(-80%);
  }

  100% {
    transform: translateY(-10%);
  }
}

.scroll-container {
  width: 300px;
  height: 300px;
  /* overflow: hidden; */
  position: relative;
  transform: rotate(-45deg);
}

.scroll-content>div:nth-child(2) {
  animation: scrollOne 15s ease 0s infinite;
}

.scroll-content {
  display: flex;
  gap: 25px;
}

.scroll-content>div:first-child {
  animation: scroll 15s ease 0s infinite;
  top: 0;
  left: 0;
  height: auto;
}

.scroll-content img {
  width: 100%;
  object-fit: cover;
}

/* ====================== [ Start progress-wrap ] ====================== */
.progress-wrap {
  position: fixed;
  right: 50px;
  bottom: 70px;
  height: 46px;
  width: 46px;
  cursor: pointer;
  display: block;
  border-radius: 50px;
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.2);
  z-index: 10000;
  opacity: 0;
  visibility: hidden;
  transform: translateY(15px);
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.progress-wrap.active-progress {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.progress-wrap::after {
  position: absolute;
  content: "";
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/toparrow_icon.svg");
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  left: 0;
  top: 0;
  height: 46px;
  width: 46px;
  cursor: pointer;
  display: block;
  z-index: 1;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.lightScrollIcon::after {
  color: #ecedf3 !important;
}

.progress-wrap:hover::after {
  opacity: 0;
}

.progress-wrap::before {
  position: absolute;
  content: '';
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/toparrow_icon.svg");
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  opacity: 0;
  left: 0;
  top: 0;
  height: 46px;
  width: 46px;
  cursor: pointer;
  display: block;
  z-index: 2;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.progress-wrap:hover::before {
  opacity: 1;
}

.progress-wrap svg path {
  fill: none;
}

.progress-wrap svg.progress-circle path {
  stroke: #FF4B00;
  stroke-width: 4;
  box-sizing: border-box;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

/* ====================== [ End progress-wrap ] ====================== */





/* submit_artwork */

.otherpages_bnr {
  position: relative;
  padding: 120px 0px 40px;
}

.otherpages_bnr::before {
  content: "";
  position: absolute;
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/productsbg_bnr.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 545px;
  position: absolute;
  top: 0;
  z-index: -1;
}

.submitArtWorkFrame {
  border-radius: 20px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.20);
  background-color: #FFFFFF;
}

.submitArtWorkFrameHeading {
  background: #080B36;
  border-radius: 20px 20px 0px 0px;
  padding: 30px;
  position: relative;
}

.submitArtWorkFrameHeading>div {
  z-index: 999;
  position: relative;
}

.submitArtWorkFrameHeading::after {
  content: "";
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/phaserBeam_ellipse.svg");
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: cover;
  width: 176px;
  height: 176px;
  display: block;
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.submitArtWorkFrameHeading::before {
  content: "";
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/quillTip_circle.svg");
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: cover;
  width: 68px;
  height: 68px;
  display: block;
  position: absolute;
  top: 30px;
  right: 80px;
}

.artwork_ConditionsTab {
  position: relative;
  bottom: -50px;
}

.artwork_ConditionsTab ul {
  padding: 0px;
  margin: 0px;
  display: flex;
  align-items: center;
  list-style: none;
  gap: 16px;
}

.artwork_ConditionsTab ul li a,
.artwork_ConditionsTab ul li button {
  padding: 10px 20px;
  border-radius: 7px;
  border: unset;
  border-bottom: 2px solid #FF4B00;
  background: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: #FF4B00;
  text-decoration: unset;
  cursor: pointer;
}

.artwork_ConditionsTab ul li a:hover,
.artwork_ConditionsTab ul li button:hover {
  /* border: 2px solid #FF4B00; */
  background: #FF4B00;
  color: #FFFFFF;
}

.artwork_ConditionsTab ul li a.active,
.artwork_ConditionsTab ul li button.active {
  /* border: 2px solid #FF4B00; */
  background: #FF4B00;
  color: #FFFFFF;
}

.submitArtWorkFrame_body {
  padding: 100px 120px 80px;
}

.uploadYourArtwork {
  border-radius: 5px;
  border: 1px dashed #FF4B00;
  padding: 24px;
  width: 100%;
  cursor: pointer;
}



.sustainability_bnr {
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/sustainabilitybg_bnr.jpg");
  width: 100%;
  /* height: 700px; */
  height: 600px;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 80px 0px;
}

.Meets_durability_Excellence {
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/top-view-fir-tree-forest.jpg");
  width: 100%;
  height: auto;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 80px 0px;
  background-attachment: fixed;
}

@media only screen and (max-device-width: 1366px) {
  .Meets_durability_Excellence {
    background-attachment: scroll;
  }
}

.susabilityMeet_items {
  padding: 24px;
  width: 100%;
  height: 400px;
  background-color: #FFF;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
  transition: .5s;
}

.susabilityMeet_items:hover {
  transform: translateY(-16px);
}


.Meets_durability_Excellence .row>.col-lg-4:first-child .susabilityMeet_items {
  border-radius: 8px 8px 70px 8px;
  border-right: 5px solid #006C4B;
  border-bottom: 5px solid #006C4B;
}

.Meets_durability_Excellence .row>.col-lg-4:nth-child(2) .susabilityMeet_items {
  border-radius: 8px 8px 70px 70px;
  border-right: 5px solid #006C4B;
  border-bottom: 5px solid #006C4B;
  border-left: 5px solid #006C4B;

}

.Meets_durability_Excellence .row>.col-lg-4:nth-child(3) .susabilityMeet_items {
  border-radius: 8px 8px 8px 70px;
  border-bottom: 5px solid #006C4B;
  border-left: 5px solid #006C4B;
}

.Meets_durability_Excellence .row>.col-lg-4:nth-child(4) .susabilityMeet_items {
  border-radius: 8px 70px 8px 8px;
  border-top: 5px solid #006C4B;
  border-right: 5px solid #006C4B;
}

.Meets_durability_Excellence .row>.col-lg-4:nth-child(5) .susabilityMeet_items {
  border-radius: 70px 70px 8px 8px;
  border-top: 5px solid #006C4B;
  border-right: 5px solid #006C4B;
  border-left: 5px solid #006C4B;
}

.Meets_durability_Excellence .row>.col-lg-4:nth-child(6) .susabilityMeet_items {
  border-radius: 70px 8px 8px 8px;
  border-top: 5px solid #006C4B;
  border-left: 5px solid #006C4B;
}

.ourEcoFocused_Operations {
  padding: 80px 0px;
}

.ecoFocus_operationItem {
  border-radius: 5px;
  background: #FFF;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
  padding: 24px;
  display: flex;
  align-items: center;
  gap: 16px;
  position: relative;
}

.ecoFocusOper_img {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  margin-left: -50px;
  width: 50px;
  height: 50px;
}

.ecoFocusOper_img img {
  width: 28px;
}

.ecoFocu_oprtion_leftImg img {
  /* background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/ecoFocused_operations.png"); */
  width: 100%;
  /* background-repeat: no-repeat;
  background-size: contain;
  background-position: left top; */
  /* height: 630px;
  display: block;
  left: 0px;
  position: absolute !important;
  display: block; */
}



/* body:has(.siteHeader_wrapper ul.navbar-nav li.nav-item.dropdown:hover ul.dropdown-menu) {
  position: relative;
}

body:has(.siteHeader_wrapper ul.navbar-nav li.nav-item.dropdown:hover ul.dropdown-menu):after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #000;
  opacity: .5;
  transition: .5s;
  z-index: 999;
} */

.industries_brn {
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/industries_bnr_bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: auto;
  padding: 80px 0px;
  margin-bottom: 40px;
}

.industries_brn h1 {
  font-size: 32px;
  line-height: 1.5;
  font-weight: 600;
  color: #080B36;
  margin: 0px;
}

.products_brn {
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/productsbg_bnr.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  /* background-position: center; */
  width: 100%;
  height: auto;
  padding: 80px 0px;
  margin-bottom: 40px;
}

.products_brn h1 {
  font-size: 32px;
  line-height: 1.5;
  font-weight: 600;
  color: #080B36;
  margin: 0px;
}

.whoweare_bnr {
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/whoweare_bnr.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  // background-position: center;
  width: 100%;
  height: auto;
  padding: 80px 0px;
  /* margin-bottom: 40px; */
}

.whoweare_bnr h1 {
  font-size: 32px;
  line-height: 1.5;
  font-weight: 600;
  color: #080B36;
  margin: 0px;
}

.violetVerbena_bg {
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/violetVerbena_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: auto;
  padding: 120px 0px 80px;
}

.industriesVideo_Frame {
  position: relative;
}

.industriesVideo_Frame::after {
  content: "";
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/industriesVideoFrame.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  position: absolute;
  width: 100%;
  left: 0px;
  top: 0px;
  height: 100%;

}

.industriesVideo_Frame video {
  padding: 16px;
  width: 100%;
  height: 566px;
  border-radius: 0px 100px;
  object-fit: cover;
}

.industriesCard,
.rollCardDiv,
.whiteCardDiv,
.careerCard {
  position: relative;
}

.whiteCardDiv2 {
  position: relative;
  z-index: -1;
}

.overlay_card {
  position: relative;
  bottom: 56.3%;
  scale: 0.64;
  right: 17.2%;
}

.industriesCard img {
  position: absolute;
  left: 20px;
  width: 180px;
  bottom: 20px;
  transform: rotate(270deg);
}

.careerCard img {
  position: absolute;
  left: 20px;
  width: 180px;
  bottom: 20px;
  transform: rotate(270deg);
}

.rollCardDiv img {
  position: absolute;
  /* left: 395px; */
  left: 0;
  right: 0;
  margin: auto;
  width: 180px;
  bottom: -180px;
  scale: 0.6;
  z-index: 99;
  opacity: 0;
  /* transform: rotate(90deg); */
}

.whiteCardDiv img,
.whiteCardDiv2 img {
  position: absolute;
  left: -1500px;
  top: -50px;
  right: 0;
  margin: auto;
  width: 280px;
  scale: 0.7;
  transform: rotate(-45deg);
}

.z-index {
  z-index: 9;
}

/* #rollCard {
  position: absolute;
  left: 20px;
  width: 180px;
  bottom: 20px;
  transform: rotate(270deg);
} */



.VerticalStepsWrapper {
  counter-reset: my-sec-counter;
}

.VerticalSteps {
  display: flex;
  gap: 16px;
}

.VerticalSteps>div {
  flex: 1 1 0;
}

.VerticalStepsWrapper .VerticalSteps:first-child>div:first-child {
  padding-top: 0px;
}

.VerticalSteps>div:first-child {
  margin-left: auto;
  display: block;
  text-align: right;
  padding: 50px 0px;
}

.VerticalSteps>div:nth-child(2) {
  width: 80px;
  flex: 0;
}

.VerticalStepsWrapper .VerticalSteps:nth-child(odd)>div:nth-child(3) {
  padding: 50px 0px 50px;
}

.VerticalStepsWrapper .VerticalSteps:nth-child(even)>div:nth-child(3) {
  padding: 0px 0px 80px;
}



.VerticalStepsWrapper .VerticalSteps:nth-child(odd)>div:first-child {
  padding: 0px 0px 80px;
}

.VerticalStepsWrapper .VerticalSteps:nth-child(even)>div:first-child {
  padding: 50px 0px 50px;
}

.centerStroke {
  border-left: 2px solid #868686;
  margin: 0px 20px;
  position: relative;
}

.VerticalSteps img {
  width: 100%;
}

.centerStroke::before {
  border-top: 2px solid #FF4B00;
  content: "";
  height: 2px;
  position: absolute;
  top: 0px;
  width: 26px;
}

.centerStroke::after {
  counter-increment: my-sec-counter;
  content: counter(my-sec-counter);
  border: 2px dashed #FF4B00;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  left: 26px;
  position: absolute;
  top: -16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  color: #8A8BA4;
}



.VerticalStepsWrapper .VerticalSteps:nth-child(even) .centerStroke::before {
  right: 0px;
}

.VerticalStepsWrapper .VerticalSteps:nth-child(even) .centerStroke::after {
  right: 26px !important;
  left: unset !important;
}

.VerticalStepsWrapper .VerticalSteps:last-child>div {
  padding-bottom: 0px !important;
}


/* body{
  position: relative;
}
body::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 800 600"><path class="custom-path" fill="none" stroke="red" d="M372.163,1930.711C369.925,1929.163,374.853,1776.715,372.091,1774.754,319.418,1737.437,117.22943,1602.46,121.26551,1571.88892,128.234,1519.099,536.675,1523.18,539.195,1489.489,541.093,1464.11,539.289,1265.89,531.42,1265.903,472.895,1265.997,197.911,1262.953,193.518,1263.151,176.661,1263.834,149.49808,1245.93148,156.46559,1228.6146,179.949,1170.249,368.02916,1116.5715,368.00174,1094.54233,367.818,947.679,366.948,486.94625,364.415,474.396,352.81,416.899,118.28,288.056,176.86659,238.12412,198.666,219.544,396.692,251.795,455.86,230.559,489.171,218.603,480.475,70.14,479.637,30.644"  /></svg>');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
} */


.readymadeSol_frame {
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/infiniteNightFrame.png") no-repeat;
  background-size: contain;
  width: 100%;
  padding: 40px;
  height: 344px;
  position: relative;
}

.readymadeSol_frame::after {
  content: "";
  width: 634px;
  height: 643px;
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/readyMade_solutions.png");
  background-position: top right;
  display: block;
  position: absolute;
  right: 0px;
  bottom: -70px;
  background-repeat: no-repeat;
}

.readyMade_Solution {
  padding: 240px 0px 80px;
}






/* capabilitesItem Item */
.capabilitesItem {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  display: block;
}

.capabilites_Frame .capabilites_img img {
  /* min-height: 300px;
  max-height: 300px; */
  /* height: 100%; */
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.capabilites_Frame {
  position: relative;
}

.capabilitesItem .capabilites_Frame::before {
  border-radius: 10px;
  content: "";
  position: absolute;
  top: 62px;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to top, #000, transparent);
  transition: 0.5s;
}

.capabilitesItem:hover .capabilites_Frame:before {
  content: unset !important;
}

.capabilites_caption {
  width: 100%;
  z-index: 999;
  position: absolute;
  bottom: 30px;
  padding: 0px 24px;
  transition: 0.5s;
}

.capabilites_caption img {
  display: none !important;
  width: 80px !important;
}

.capabilitesItem:hover .capabilites_Frame .capabilites_caption img {
  display: block !important;
}

.capabilitesItem:hover .capabilites_Frame .capabilites_caption {
  top: 0px;
  bottom: 0px;
  left: 0;
  right: 0;
  text-align: center;
  transition: 0.5s;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
}

.capabilitesItem:hover .capabilites_Frame::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #000;
  opacity: 0.73;
  border-radius: 10px;
  transition: 0.5s;
}

.capabilites_caption h3 {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 0px;
  color: #FFFFFF;
}

.capabilitesItem:hover .capabilites_caption {
  -webkit-animation: tracking-in-expand-fwd-bottom 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
  animation: tracking-in-expand-fwd-bottom 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

.titleRightBorder {
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
}

.titleRightBorder:after {
  content: "";
  flex: 1 1;
  border-bottom: 2px dashed rgba(255, 75, 0, 0.30);
  -webkit-margin-start: 1vw;
  margin-inline-start: 1vw;
}

.titleRightBorder::before {
  content: "";
  flex: 1 1;
  border-bottom: 2px dashed rgba(255, 75, 0, 0.30);
  -webkit-margin-end: 1vw;
  margin-inline-end: 1vw;
}

.doctorBgFrame {
  position: relative;
  padding: 40px 0px;
  margin-bottom: 40px;
}

.doctorBgFrame::after {
  content: "";
  background: #F9F9FA;
  position: absolute;
  left: 0;
  right: 0;
  top: 0%;
  bottom: 0;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0px;
  z-index: -1;
}

.autoDoctorFrame {
  background-color: #F9F9FA;
  padding: 40px 0px;

}

img.industries_pageText {
  width: 100%;
  object-fit: cover;
}

h2.pageText {
  color: transparent;
  -webkit-text-fill-color: #FFF;
  -webkit-text-stroke-width: 1.15px;
  -webkit-text-stroke-color: #A6A6A6;
  font-size: 44px;
  font-weight: 600;
  line-height: 1.5;
  margin: 0px;
  text-transform: uppercase;
  opacity: 0.7;
    font-family: sans-serif;
}

.contactusFrame {
  border-radius: 8px;
  background: #080B36;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: auto;
  position: relative;
  padding: 16px 16px 16px 34px;
}

.contactForm_whiteFrame {
  border-radius: 8px;
  background: #FFF;
  padding: 30px;
  position: relative;
  z-index: 999;
}

.contactusFrame::before {
  content: "";
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/contactus_quillTip_circle.svg");
  width: 403px;
  height: 391px;
  position: absolute;
  bottom: 10%;
  left: 25%;
}

.contactusFrame::after {
  content: "";
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/phaserBeam_halfEllipse.svg");
  width: 226px;
  height: 113px;
  position: absolute;
  bottom: 0px;
  left: 30%;
}

ul.contact_detail {
  padding: 0px;
  margin: 0px;
  list-style: none;
  width: 350px;
}

ul.contact_detail li {
  margin-bottom: 12px;
}

ul.contact_detail li:last-child {
  margin-bottom: 0px;
}

ul.contact_detail li a {
  padding: 12px 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 12px;
}

ul.contact_detail li a:hover {
  background: rgba(138, 139, 164, 0.30);
}

ul.contact_detail li:hover a {
  color: #FFFFFF;
  text-decoration: unset;
}

ul.contact_detail li h5 {
  font-size: 16px;
  line-height: 1.5;

  margin: 0px;
  color: #FFFFFF;
}




.capabilities_Bnr_Frame {
  position: relative;
}

.capabilities_Bnr_Frame::after {
  content: "";
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/capabilities_videoFrame.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  position: absolute;
  width: 100%;
  left: 0px;
  top: 0px;
  height: 100%;
  z-index: 1;

}

.capabilities_Bnr_Frame img.capabilities_bnr_img {
  padding: 16px 0px 16px 20px;
  width: 100%;
  height: 572px;
  border-radius: 250px 0px 0px 0px;
  object-fit: cover;
  z-index: 2;
}

.CapabilitesProductCaroucel {
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/CapabilitesPrdct_Caroucel_bg.png") no-repeat;
  background-size: contain;
  background-position: center;
  width: 100%;
  /* height: 467px; */
  height: 633px;
  position: relative;
  margin-top: 80px;
}

.CapabilitesProductCaroucel::after,
.Capabilites_displays_carousel::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0.9;
  background: rgba(0, 2, 32, 0.7);
}

.CapabilitesProductCaroucel .carousel,
.Capabilites_displays_carousel .carousel {
  position: relative;
  z-index: 999;
}

.CapabilitesProductCaroucel .carousel-content,
.Capabilites_displays_carousel .carousel-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 70%;
  align-items: center;
  margin: auto;
}





.carousel-control {
  margin: 0px;
  position: absolute;
  bottom: 30px;
  right: 0;
  display: flex;
  align-items: center;
  gap: 12px;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-next,
.carousel-control-prev {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  position: unset;
}

.carousel-control-next img {
  filter: brightness(0) saturate(100%) invert(46%) sepia(90%) saturate(4934%) hue-rotate(1deg) brightness(102%) contrast(107%);
}

.carousel-control-next {
  background-color: #FF4B00 !important;
}

.carousel-control-next img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7479%) hue-rotate(70deg) brightness(99%) contrast(107%);
}

.carousel-control-prev {
  background-color: #080B36 !important;
}

.carousel-control-prev img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7479%) hue-rotate(70deg) brightness(99%) contrast(107%);
}

.unveiling_Ideal_Substrate {
  // background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/unveiling_Ideal_Substrate_bg.png");
  // background-size: cover;
  // background-repeat: no-repeat;
  // width: 100%;
  // height: auto;
  // background-position: right;
  padding: 80px 0px 40px;
  margin-bottom: 80px;
}

/* .paperBeyond_leftImg {
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/paperBeyond_left_img.png");
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left top;
  height: 630px;
  display: block;
  left: 0px;
  position: absolute !important;
  display: block;
} */

.paperBeyond_leftImg img {
  width: 100%;
}

.elevateBrand_newHeights_leftImg {
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/think_outsideboximg.png");
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right top;
  height: 990px;
  display: block;
  right: 0px;
  position: absolute !important;
  display: block;
}

.icyLilac_bgauto {
  background: #E6E8F8;
  border-radius: 8px;
  padding: 40px;
  height: auto;
}

.newsroom_bnr {
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/newsroom_bnr.jpg");
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  height: 470px;
}

.newsroom_bnr .container>div {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 60%;
  margin: auto;
}

.dateRightBorder {
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
}

.dateRightBorder:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid #E6E6E6;
  -webkit-margin-start: 1vw;
  margin-inline-start: 1vw;
}

.newsroomItems:after {
  content: "";
  border-bottom: 1px solid #E6E6E6;
  width: 100%;
  display: block;
  /* padding-bottom: 16px; */
  margin: 20px 0px;
}

.newsroomItem_img img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}


.newsroomCenterItem_img img {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

.maWhiteFrmae {
  background: #F4F7FB;
  border-radius: 8px;
  padding: 20px;
  border-right: 1px solid #080B36;
  border-left: 1px solid #080B36;
}


.newsroom_wrapper {
  padding: 80px 0px;
}

.newsroom_blog_img img {
  width: 100%;
  object-fit: cover;
  height: 219px;
}

.newsroom_blog {
  padding: 0px 0px 80px;
}



.newsroomOverview_img img {
  width: 100%;
  height: 437px;
  object-fit: cover;
}

.blogpage_bnr {
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/blog_bnr.jpg");
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  height: 470px;
}

.blogpage_bnr .container>div {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 60%;
  margin: auto;
}

.leadershipTeam {
  padding: 80px 0px;
}

.leadershipTeam_img {
  position: relative;
  overflow: hidden;
  margin-bottom: 16px;
}


.leadershipTeam_item {
  width: 250px;
  margin: auto;
}

.leadershipTeam .row .col-lg-3:first-child .leadershipTeam_item .leadershipTeam_img::before {
  background: #FFA37D;
}

.leadershipTeam .row .col-lg-3:nth-child(2) .leadershipTeam_item .leadershipTeam_img::before {
  background: #B8C5F0;
}

.leadershipTeam .row .col-lg-3:nth-child(3) .leadershipTeam_item .leadershipTeam_img::before {
  background: #F6F6F6;
}

.leadershipTeam .row .col-lg-3:nth-child(4) .leadershipTeam_item .leadershipTeam_img::before {
  background: rgba(8, 11, 54, 0.20);
}



.leadershipTeam .row .col-lg-3:nth-child(5) .leadershipTeam_item .leadershipTeam_img::before {
  background: #FFA37D;
}

.leadershipTeam .row .col-lg-3:nth-child(6) .leadershipTeam_item .leadershipTeam_img::before {
  background: #CECED7;
}

.leadershipTeam .row .col-lg-3:nth-child(7) .leadershipTeam_item .leadershipTeam_img::before {
  background: #B8C5F0;
}

.leadershipTeam .row .col-lg-3:nth-child(8) .leadershipTeam_item .leadershipTeam_img::before {
  background: #CECED7;
}

.leadershipTeam .row .col-lg-3:nth-child(9) .leadershipTeam_item .leadershipTeam_img::before {
  background: #F6F6F6;
}



.leadershipTeam_img::before {
  content: "";
  border-radius: 0px 44.093px 0px 44.09px;
  width: 100%;
  height: 278px;
  display: block;
  position: absolute;
  bottom: 0;
}

.leadershipTeam_item {
  cursor: pointer;
}

.leadershipTeam_item:hover .leadershipTeam_img::after {
  content: "";
  border-radius: 0px 0px 0px 44.09px;
  background-repeat: no-repeat;
  width: 100%;
  background-size: contain;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  /* top: 88px; */
  top: 0;
  bottom: 0;
  animation: slideUp 0.5s ease-in-out;


}

.row .col-lg-3:first-child .leadershipTeam_item:hover .leadershipTeam_img::after {
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/first_leadership_hover_img.png");

}

.row .col-lg-3:nth-child(2) .leadershipTeam_item:hover .leadershipTeam_img::after {
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/perrymorgan_ls_hover_img.png");

}

.row .col-lg-3:nth-child(3) .leadershipTeam_item:hover .leadershipTeam_img::after {
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/alexWhite_ls_hover_img.png");

}

.row .col-lg-3:nth-child(4) .leadershipTeam_item:hover .leadershipTeam_img::after {
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/leeton_lee_ls_hover_img.png");

}


/* New tema */
.row .col-lg-3:nth-child(5) .leadershipTeam_item:hover .leadershipTeam_img::after {
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/first_leadership_hover_img.png");

}

.row .col-lg-3:nth-child(6) .leadershipTeam_item:hover .leadershipTeam_img::after {
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/perrymorgan_ls_hover_img.png");

}

.row .col-lg-3:nth-child(7) .leadershipTeam_item:hover .leadershipTeam_img::after {
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/alexWhite_ls_hover_img.png");

}

.row .col-lg-3:nth-child(8) .leadershipTeam_item:hover .leadershipTeam_img::after {
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/leeton_lee_ls_hover_img.png");

}

.row .col-lg-3:nth-child(9) .leadershipTeam_item:hover .leadershipTeam_img::after {
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/first_leadership_hover_img.png");

}

@keyframes slideUp {
  from {
    transform: translateY(50px);
  }

  to {
    transform: translateY(0px);
  }
}

.leadershipTeam_img img {
  width: 100%;
  height: 350px;
  border-radius: 0px 44.093px 0px 44.09px;
  object-fit: cover;
  z-index: 999;
  position: relative;
}

.leadershipFrame_img::before {
  content: "";
  border-radius: 0px 44.093px 0px 44.09px;
  width: 100%;
  height: 278px;
  display: block;
  position: absolute;
  bottom: 0;
}

.leadershipFrame_img {
  position: relative;
}

.leadershipFrame_img img {
  position: relative;
  border-radius: 0px 44.093px 0px 44.09px;
}

.leadershipFrame_img.lsfClr_first::before {
  background: #FFA37D;
}

.leadershipFrame_img.lsfClr_second::before {
  background: #B8C5F0;

}

.leadershipFrame_img.lsfClr_third::before {
  background: #F6F6F6;
}

.leadershipFrame_img.lsfClr_fourth::before {
  background: rgba(8, 11, 54, 0.20);
}

.whoweare_Bnr_Frame {
  position: relative;
}

.whoweare_Bnr_Frame img.ww_are_bnr {
  padding: 16px 0px 16px 16px;
  width: 100%;
  height: 526px;
  border-radius: 100.694px 0px;
  object-fit: cover;
  z-index: 2;
}

/* .whoweare_Bnr_Frame video {
  padding: 16px;
  width: 100%;
  height: 519px;
  border-radius: 100.694px 0px;
  object-fit: cover;
} */

.whoweare_Bnr_Frame::after {
  content: "";
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/who_we_are_videoFrame.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  position: absolute;
  width: 100%;
  left: 0px;
  top: 0px;
  height: 100%;
  z-index: 1;
}

.mission_visionSec {
  padding: 80px 0px 0px;
}

.mission_VisionItem {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
  /* height: 100%; */
  min-height: 340px;
  /* max-height: 340px; */
  -webkit-transition: .5s;
  transition: .5s;
}

.mission_VisionItem:hover {
  transform: translateY(-12px);
}

.mission_VisionItem>div {
  padding: 24px;
}

.mission_bg {
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/mission_bg.svg");
  background-repeat: no-repeat;
  border-radius: 8px 8px 70px 8px;

}

.vision_bg {
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/vision_bg.svg");
  background-repeat: no-repeat;
  border-radius: 8px 8px 8px 70px;
}

.pliDifference {
  padding: 80px 0px 0px;
}

.pliDifferenceItem {
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
  height: 100%;
  opacity: 0.9;
}

.pliDifference_header {
  border-radius: 8px 8px 0px 0px;
  background: #F4F7FB;
  padding: 16px;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pliDifference_body {
  padding: 24px;
}

.displays_Solution_bg {
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/displays_Solution_bg.jpg");
  width: 100%;
  height: auto;
  padding: 80px 0px 80px;
  background-repeat: no-repeat;
  background-size: cover;
}


.versatile_display {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
  /* height: 100%; */
  max-height: 420px;
  min-height: 420px;
  -webkit-transition: .5s;
  transition: .5s;
}

.versatile_display>div {
  padding: 24px;
}

.versatile_display img {
  width: 80px;
  height: 80px;
}

.versatile_TextRightBorder {
  display: flex;
  align-items: center;
  text-align: center;
  width: 70%;
}

.versatile_TextRightBorder:after {
  content: "";
  flex: 1 1;
  border-bottom: 2px solid #FF4B00;
  -webkit-margin-start: 1vw;
  margin-inline-start: 1vw;
}

.tempDisplay_bg {
  background: #FFF url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/temp_display_bg.svg");
  background-repeat: no-repeat;
  border-radius: 8px 8px 70px 8px;

}

.permanentDisplay_bg {
  background: #FFF url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/permanent_display_bg.svg");
  background-repeat: no-repeat;
  border-radius: 8px 8px 8px 70px;
}

.infiniteNight_rectangle {
  background: #080B36;
  padding: 50px 0px;
}

.gift_extra_touch {
  padding: 80px 0px 0px;
}

.choices_everyVision {
  padding: 0px 0px 120px;
}

/* .tailored_pkg_occasion_leftImg {
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/customized_packaging_img.png");
  width: 100%;
  background-repeat: no-repeat;
  background-position: left top;
  height: 630px;
  display: block;
  left: 0px;
  position: absolute !important;
  display: block;
} */
.tailored_pkg_occasion_leftImg {
  position: relative;
  padding: 0px;
}

.tailored_pkg_occasion_leftImg img {
  width: 100%;
  /* left: 0px;
  position: absolute !important; */

}

.fulfillment_item {
  padding: 80px 0px 0px;
}



.employeeFunded_leftImg {
  position: relative;
}

.employeeFunded_leftImg img {
  height: 630px;
  width: 100%;
  left: 0px;
  position: absolute !important;
  z-index: -1;
}

.charity_pli_coreValue {
  padding: 80px 0px 0px;
}

.emp_driven_and_funded {
  padding: 80px 0px 170px;
  overflow: hidden;
}

ul.careersSocialMenu {
  display: flex;
  align-items: center;
  margin: 0px;
  padding-left: 0px;
  list-style: none;
  gap: 24px;
}

ul.careersSocialMenu li a img:hover {
  filter: brightness(0) saturate(100%) invert(4%) sepia(53%) saturate(7499%) hue-rotate(238deg) brightness(95%) contrast(101%);
}

.jopSearchFrame {
  background: #F5F5F5;
  padding: 16px;
  border-radius: 8px;
}

.jobItem {
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
  padding: 24px;
  -webkit-transition: .5s;
  transition: .5s;
  min-height: 126px;
}

.jobItem:hover {
  transform: translateY(-8px);
}

.benefits_Item {
  padding: 24px;

  border-radius: 8px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
  height: 100%;
  min-height: 320px;
  -webkit-transition: .5s;
  transition: .5s;
}

.benefits_Item:hover {
  transform: translateY(-12px);
}

.health_wellness_bg {
  background: #FFF url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/healthWellness_bg.svg");
  background-repeat: no-repeat;
}

.financial_stability_bg {
  background: #FFF url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/financial_stability_bg.svg");
  background-repeat: no-repeat;
}

.work_life_balance_bg {
  background: #FFF url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/workLife_balance_bg.svg");
  background-repeat: no-repeat;
}

.benefits_sec {
  padding: 80px 0px;
}

.joinUs_rectangle {
  background: #080B36;
  width: 100%;
  padding: 50px 0px;
  position: relative;
  margin: 0px 0px 80px;
  overflow: hidden;
}

.career_up_animation {
  content: "";
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/joinUs_whitecard.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 279px;
  height: 100%;
  position: absolute;
  right: 10%;
  bottom: 0px;
  background-position: bottom;
}

.look_inside {
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/look_Inside.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  padding: 50px 0px;
  margin: 0px 0px 80px;
}

.ready_get_started {
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/ready_get_started.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  padding: 40px 0px;
  margin: 80px 0px;
}

.career_belowSpace {
  padding: 0px 0px 80px;
}

.empSpotlight_tab {
  justify-content: center;
  display: flex;
  padding-bottom: 100px;
}

.empSpotlight_tab ul {
  border-radius: 60px;
  background: #F5F5F5;
  padding: 10px 8px;
  display: inline-flex;
  justify-content: center;
}

.empSpotlight_tab ul li a {
  color: #080B36;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  text-decoration: unset;
  border-radius: 60px;
  padding: 12px 30px;
  display: block;
  margin-right: 15px;
}

.empSpotlight_tab ul li:last-child a {
  margin-right: 0px;
}

.empSpotlight_tab ul li a.active {
  background: #FFF;
}

.empSpotlight_tab ul li a:hover {
  background: #FFF;
}

.employeesSpotlight {
  position: relative;
  padding: 0px 0px 50px;
}

.employeesSpotlight::after {
  content: "";
  background: #080B36;
  width: 100%;
  /* height: 100%; */
  position: absolute;
  top: 30%;
  z-index: -1;
  bottom: 0px !important;
  left: 0;
  right: 0;
}



.custom_accordion {
  width: 100%;
}

.accordionItem {
  border-radius: 10px;
  background: #FFF;
  padding: 12px 16px;
  margin-bottom: 16px;
}

.accordionHeader {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.accordionHeader .icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.accordionItem.open {
  background: transparent;
  padding: 0px;
  border-radius: 0px;
}

.accordionItem.open .accordionHeader {
  border-radius: 10px;
  background: #FFF;
  padding: 12px 16px;
  margin-bottom: 8px;
}

.accordionContent {
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.10);
  display: none;
  padding: 24px 24px 24px 30px;
}



.custom_accordion .icon {
  margin-left: auto;
  transition: all 0.2s ease;
}

.custom_accordion .icon .accordion_remove {
  display: none;
}

.accordionItem.open .icon .accordion_remove {
  display: block;
}

.accordionItem.open .icon .accordion_add {
  display: none;
}


.custom_accordion .accordionItem.open .accordionContent {
  display: block !important;
}

.mediaObjects_header img {
  width: 230px;

}

.mediaObjects_header {
  position: relative;
  z-index: 999;
}

.mediaObjects_header::before {
  content: "";
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/db_star.svg");
  background-repeat: no-repeat;
  background-position: top;
  background-size: contain;
  width: 100%;
  height: 76px;
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mediaObjects {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;
}

.mediaObjects_body {
  border-radius: 0px 95.208px;
  background: #CFE3EC;
  width: 540px;
  height: 240px;
  margin-left: -30px;
  padding: 0px 24px 0px 75px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.mediaObjects_body::before {
  content: "";
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/quoteLeft_icon.svg");
  background-repeat: no-repeat;
  background-position: top;
  background-size: contain;
  position: absolute;
  width: 80px;
  height: 80px;
  top: 0px;
  left: 0px;
  transform: translate(50px, -37px);
}

.mediaObjects_body::after {
  content: "";
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/quoteRight.svg");
  background-repeat: no-repeat;
  background-position: top;
  background-size: contain;
  position: absolute;
  width: 80px;
  height: 80px;
  bottom: 0px;
  right: 0px;
  transform: translate(0px, 37px);
}

.pliValues_leftAlign {
  border-top: 1px solid #8A8BA4;
  border-right: 10px solid #8A8BA4;
  border-bottom: 1px solid #8A8BA4;
  border-left: 1px solid #8A8BA4;
}

.pliValues_Item {
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.10);
  padding: 16px;
  position: relative;
}

.pliValues_leftAlign::after {
  content: "";
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/pli_values_points.svg");
  background-repeat: no-repeat;
  background-position: right;
  width: 20px;
  height: 20px;
  position: absolute;
  right: -30px;
  top: 50%;

  transform: translateX(-50%);
}


.pliValues_rightAlign {
  border-top: 1px solid #8A8BA4;
  border-right: 1px solid #8A8BA4;
  border-bottom: 1px solid #8A8BA4;
  border-left: 10px solid #8A8BA4;
}

.pliValues_rightAlign::after {
  content: "";
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/pli_values_points.svg");
  background-repeat: no-repeat;
  background-position: right;
  width: 20px;
  height: 20px;
  position: absolute;
  left: -12px;
  top: 50%;

  transform: translateX(-50%);
}


.centercard_onedream {
  position: absolute;
  z-index: -999;
  left: 45%;
  width: 175px;
  height: 250px;
}

.centercard {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 500px;
  height: 500px;
  z-index: -1;
  margin: auto;
  /* overflow: hidden; */
}

.centercard img {
  width: 200px;
}

.centercard img.centercard_two {
  transform: rotate(12deg) translate(-152px, 0px);
  position: absolute;
  top: 100px;
}

.centercard>div:first-child {
  position: relative;
  z-index: 999;
}

.centercard::before {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.centercard::before {
  content: "";
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/zoomCircle_img.svg");
  width: 330px;
  height: 330px;
  border-radius: 50%;
  display: block;
  animation: zoom-in-zoom-out 2s ease-out infinite;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
}

/* .centercard::after, .centercard::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
  border: 5px solid rgba(138, 139, 164, 0.10);
    opacity: 0.7;
    left: 0;
    top: 0;
    border-radius: 50%;
    -webkit-animation-duration: 2.5s;
    animation-duration: 2.5s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: video-animation;
    animation-name: video-animation;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
} */
@-webkit-keyframes video-animation {
  0% {
    transform: scale(1);
  }

  20% {
    transform: scale(1.5);
  }

  40% {
    transform: scale(2);
  }

  60% {
    transform: scale(2.5);
  }

  80% {
    transform: scale(3);
  }

  100% {
    opacity: 0;
    transform: scale(3.5);
  }
}

@keyframes video-animation {
  0% {
    transform: scale(1);
  }

  20% {
    transform: scale(1.5);
  }

  40% {
    transform: scale(2);
  }

  60% {
    transform: scale(2.5);
  }

  80% {
    transform: scale(3);
  }

  100% {
    opacity: 0;
    transform: scale(3.5);
  }
}


.job_Openings {
  padding: 80px 0px 50px;
}

.capabilites_bnr {
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/capabilites_bnr.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: auto;
  padding: 80px 0px;
  /* margin-bottom: 40px; */
}

.capabilites_bnr h1 {
  font-size: 32px;
  line-height: 1.5;
  font-weight: 600;
  color: #080B36;
  margin: 0px;
}

.prdt_hspitlty_event {
  padding: 40px 0px 0px;
}

.product_tab {
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
}

.product_tab ul {
  background: #F5F5F5;
  border-radius: 60px;
  display: inline-flex;
  justify-content: center;
  padding: 8px 12px;
}

.product_tab ul li a {
  color: #080B36;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  text-decoration: unset;
  border-radius: 60px;
  padding: 12px 30px;
  display: block;
  margin-right: 15px;
}

.product_tab ul li a.active {
  background: #080B36;
  color: #FFFFFF;
}

.product_Item {
  margin-bottom: 24px;
  width: 100%;
  cursor: pointer;
  transition: .5s;
}

.product_Item:hover {
  transform: translateY(-7px);
}

.product_Item>div:first-child {
  border-radius: 10px;
  background: #F5F5F5;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
  padding: 16px;
  position: relative;
  z-index: 999;
  min-height: 190px;
  max-height: 190px;
}

.product_Item>div:first-child img {
  width: 100%;
  object-fit: contain;
  height: 160px;
}

.product_Item>div:nth-child(2) {
  border-radius: 0px 0px 10px 10px;
  background: #8A8BA4;
  padding: 24px 16px 12px;
  margin-top: -12px;
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mfp-title {
  display: none;
}

.how_we_achieveit {
  padding: 80px 0px 50px;
  position: relative;
}

.how_we_achieveit::after {
  content: "";
  background: #F5F5F5;
  width: 100%;
  /* height: 100%; */
  bottom: 0;
  top: 30%;
  position: absolute;
  z-index: -1;
}


.how_we_achieveit_item {

  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
  /* max-height: 270px; */
  /* min-height: 270px; */
  /* max-height: 340px;  */
  min-height: 340px;
  -webkit-transition: .5s;
  transition: .5s;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 24px;
}

.how_we_achieveit_item:hover {
  transform: translateY(-12px);
}

.how_we_achieveit_item>div {
  padding: 24px 16px;
}

.how_we_achieveit_item img {
  width: 75px;
  height: 75px;
}

.achieveit_TextRightBorder {
  display: flex;
  align-items: center;
  text-align: center;
  width: 85%;
}

.achieveit_TextRightBorder:after {
  content: "";
  flex: 1 1;
  border-bottom: 2px solid #FF4B00;
  -webkit-margin-start: 1vw;
  margin-inline-start: 1vw;
}

.how_we_achieveit .row .col-md-4:nth-of-type(1) .how_we_achieveit_item {
  background: #FFF url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/cardproduction_capacity_bg.svg");
  background-repeat: no-repeat;
}

.how_we_achieveit .row .col-md-4:nth-of-type(2) .how_we_achieveit_item {
  background: #FFF url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/prdctfloor_wf_bg.svg");
  background-repeat: no-repeat;
}

.how_we_achieveit .row .col-md-4:nth-of-type(3) .how_we_achieveit_item {
  background: #FFF url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/continuous_investments_bg.svg");
  background-repeat: no-repeat;
}

.how_we_achieveit .row .col-md-4:nth-of-type(4) .how_we_achieveit_item {
  background: #FFF url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/cuttingEdge_machinery_bg.svg");
  background-repeat: no-repeat;
}

.how_we_achieveit .row .col-md-4:nth-of-type(5) .how_we_achieveit_item {
  background: #FFF url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/expfulfillment_capabilities_bg.svg");
  background-repeat: no-repeat;
}

.how_we_achieveit .row .col-md-4:nth-of-type(6) .how_we_achieveit_item {
  background: #FFF url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/adaptableworkforce_bg.svg");
  background-repeat: no-repeat;
}

.how_we_achieveit .row .col-md-3:nth-of-type(7) .how_we_achieveit_item {
  background: #FFF url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/ensuringExcellence_bg.svg");
  background-repeat: no-repeat;
}


.capabilities_equipment {
  padding: 80px 0px;
}





.capabilities_equipment_tab {
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
}

.capabilities_equipment_tab ul {
  background: #F5F5F5;
  border-radius: 60px;
  display: inline-flex;
  justify-content: center;
  padding: 8px 12px;
}

.capabilities_equipment_tab ul li a {
  color: #080B36;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  text-decoration: unset;
  border-radius: 60px;
  padding: 12px 30px;
  display: block;
  margin-right: 15px;
}

.capabilities_equipment_tab ul li a.active {
  background: #080B36;
  color: #FFFFFF;
}

.equipment_table table {
  border: 1px solid #F0F0F0;
  width: 100%;
  margin: 0px;
}

.equipment_table table th,
.equipment_table table td {
  padding: 12px 16px;
}

.equipment_table table tr:nth-child(even) {
  background: #F5F5F5;
}

.equipment_table table th {
  border-right: 1px solid #F0F0F0;
}


.certifications_Item {
  text-align: center;
  position: relative;
  padding: 0px 24px;
}

.certifications_Item img {
  margin-bottom: 34px;
}


.certifications_Item::after {
  content: "";
  border-right: 1px solid #CCCCCC;
  position: absolute;
  right: 0px;
  top: 50%;
  /* bottom: 0; */
  height: 50%;
  transform: translateY(-50%);
}

.certifications_sec .col-md-4:last-child .certifications_Item::after {
  content: unset;
}


.please_visit_us {
  padding: 80px 0px;
}

.visit_Item {
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10);
}

.visit_content {
  padding: 24px 16px;
  min-height: 185px;
  max-height: 185px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.visit_content h5 {
  position: relative;
}

.visit_content h5::after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #CCC;
  /* top: 35px; */
  padding: 18px 0px;
  width: 100%;
  left: 0;
  right: 0px;
  height: 1px;
}

.visit_content p {
  margin-top: 12px;
}

.visit_Item .visitus_img img {
  width: 100%;
  object-fit: cover;
  border-radius: 8px 8px 0px 0px;
  min-height: 223px;
  max-height: 223px;
  -webkit-transition: .5s;
  transition: .5s;
}

.visit_Item .visitus_img {
  position: relative;
  overflow: hidden;
  border-radius: 8px 8px 0px 0px;
}

.visit_Item:hover .visitus_img img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  border-radius: 8px 8px 0px 0px;
}

.headquartersBox {
  position: absolute;
  right: 16px;
  top: 12px;
  background: #080B36;
  display: inline-flex;
  gap: 10px;
  align-items: center;
  border-radius: 5px;
  padding: 6px 12px;
}

/* .headquartersBox img{
  width: 24px;
} */


.indust_bnr_animation {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  justify-content: end;
  position: relative;
  /* opacity: 0; */
  scale: 0.9
}

.loyalty_banner_animation,
.sustainability_animation {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  justify-content: end;
  position: relative;
  left: 75px;
  top: 50px;
  scale: 0.9;
}

.hospitality_animation {
  opacity: 0;
}

.indust_bnr_animation>div,
.loyalty_banner_animation>div,
.sustainability_animation>div {
  position: absolute;
}

.hospitality_four {
  opacity: 0;
}

.sustainability_animation {
  width: 100%;
  border-radius: 8px;
}

.indust_bnr_animation img,
.loyalty_banner_animation img {
  width: 300px;
  border-radius: 8px;
}

.comprehensive_img {
  padding-top: 100px;
}

.meet_team_bg {
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/meet_team_bg.jpg");
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}

.meetOur_team_sec {
  padding: 0px 0px 80px;
}



.post-item__meta-wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 12px;
}


.blogdate {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  margin: 0px;
  color: #FF4B00;
  text-transform: capitalize;
}

/* .post-item__categories a{
 font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
  margin: 0px;
  color: #FF4B00;
  display: block;
} */

.view_btn {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  color: #080B36;
  display: inline-flex;
  gap: 12px;
  align-items: center;
  cursor: pointer;
}

.view_btn:hover {
  color: #FF4B00;
  text-decoration: unset;
}

.view_btn:hover img {
  filter: brightness(0) saturate(100%) invert(36%) sepia(61%) saturate(4234%) hue-rotate(1deg) brightness(103%) contrast(106%);
}

.graySocial_icon {
  display: flex;
  align-items: center;
  gap: 16px;
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.grossary_terms {
  padding: 80px 0px;
}


.searchBnr {
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/search_bg.png") lightgray 50% / cover no-repeat;
  background-position: bottom center;
  width: 100%;
  height: 400px;
}

.searchBnr .container>div {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;
}

.search_show {
  padding: 50px 0px;
}

.searchResultsGroup {
  padding: 0px;
  margin: 0px;
  list-style: none;
  border-bottom: 1px solid #E6E6E6;
}


.searchResultsGroup li {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: #252525;
  padding: 12px 16px;
  cursor: pointer;
}

.searchResultsGroup li:first-child {
  border-radius: 5px;
  background: #FFF;
  /* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10); */
}


.searchinputGroup {
  display: flex;
}

.searchinputGroupText {
  display: flex;
  align-items: center;
  padding: 0px 0px 0px 12px;
  text-align: center;
  white-space: nowrap;
  background-color: #F5F5F5;
  border: 1px solid #F5F5F5;
  border-radius: 7px 0px 0px 7px;

  height: 44px;
}

.searchinputGroup input {
  background-color: #F5F5F5;
  border: 1px solid #F5F5F5;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
  width: 100%;
  height: 44px;
  color: #080B36;
  border-radius: 0px 7px 7px 0px;

  padding: 0px 12px 0px 12px;
  outline: unset;
}

.searchinputGroup input::placeholder {
  color: #080B36;
}






.smallSearchFrameGroup {
  list-style: none;
  padding: 7px;
  max-width: 100%;
  width: 100%;
  height: auto;
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
  position: absolute;
  z-index: 999;
  transform: translateY(40px);
  overflow-y: auto;
  margin: 0px;
}


.smallSearchFrameGroup::-webkit-scrollbar {
  width: 8px;
  height: 6px;
}

/* Track */
.smallSearchFrameGroup::-webkit-scrollbar-track {
  background: #e5e7eb;
  border-radius: 10px;
}

/* Handle */
.smallSearchFrameGroup::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}



.listSearchDiv {
  color: #777;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 8px 12px;
}

.listSearchDiv div:last-child {
  margin-bottom: 0px;
}

.listSearchDiv:hover {
  background: #F5F5F5;
}



.infNight_imgOutline {
  position: relative
}

.infNight_imgOutline::after {
  content: "";
  position: absolute;
  border: 3px solid rgba(8, 11, 54, 0.40);
  border-radius: 8px;
  width: 100%;
  height: 100%;
  left: 18px;
  right: 0;
  top: 18px;
  bottom: 0px;
  z-index: -1;
}

.infNight_imgOutline img {
  border-radius: 8px;
  width: 100%;
}


.phaserBeam_imgOutline {
  position: relative
}

.phaserBeam_imgOutline::after {
  content: "";
  position: absolute;
  border: 3px solid rgba(255, 75, 0, 0.40);
  border-radius: 8px;
  width: 100%;
  height: 100%;
  left: 18px;
  right: 0;
  top: 18px;
  bottom: 0px;
  z-index: -1;
}

.phaserBeam_imgOutline img {
  border-radius: 8px;
  width: 100%;
}

.phaserBeam_imgShowdow {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.comprehensiveFirst_img img {
  width: 390px !important;
}

.whiteFrameImg {
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 0px 10.1508px 0px rgba(0, 0, 0, 0.25);
  padding: 10px;
}

.whiteFrameImg img {
  border-radius: 10px;

}

img.comprehensiveServices_evenly {
  min-height: 227px;
  object-fit: cover;
}

.mobileSearch {
  display: none;
}

.reliability_Sea_img {
  width: 200px !important;
  padding-top: 100px;
}

.diverseRange_EntertainmentTypes {
  padding-top: 130px;
}
#predesigned_eventcardbg .product_Item>div:first-child img {
  border-radius: 10px;
  object-fit: cover;
}
#predesigned_eventcardbg .mfp-img {
  border-radius: 10px;
}

.blogContentFrame img{
    max-width: 100%;
}

.newsContentFrame img{
      max-width: 100%;
}

.interestingFacts_frame {
  background-color: #F9F9FA;
  border-radius: 10px;
  padding: 24px;
}

.giftcardFraud_prevention_bnr {
  background: url("https://plicards.s3.us-west-1.amazonaws.com/pli-assets-new/gift_card_fraud_prevention_bg.jpg");
  width: 100%;
  height: 600px;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 80px 0px;
}

.pluggedIn_borderFrame {
  border: 1px solid #FF4B00;
  border-radius: 50px;
  padding: 34px;
  position: relative;
  width: 900px;
  margin: 0px auto;
}

.pluggedIn_borderFrame::before {
  content: "";
  background: #fff url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/doublequotes_start.svg");
  background-position: top;
  width: 90px;
  height: 47px;
  position: absolute;
  top: -30px;
  background-repeat: no-repeat;
  background-size: 60px;
  left: 50px;
}

.pluggedIn_borderFrame::after {
  content: "";
  background: #fff url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/doublequotes_end.svg");
  background-position: top;
  width: 90px;
  height: 47px;
  position: absolute;
  bottom: -20px;
  background-repeat: no-repeat;
  background-size: 60px;
  right: 50px;
}

.pluggedIn_Rectangle {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  background: #fff;
  border-radius: 30px;
  padding: 24px;
}

.interesting_Facts {
  padding: 80px 0px;
}

.securityLabels_sec {
  padding: 0px 0px 80px;
}

.packaging_gcfp {
  padding: 0px 0px 80px;
}

.coldGlueTechnique_sec {
  padding: 0px 0px 80px;
}

.pluggedIn_sec {
  padding: 0px 0px 120px;
}

.gcfp_sourceBottom {
  padding: 0px 0px 50px;
}

@media (max-width: 767px) {
  .showSearch {
    height: 61.36px;
  }

  .jobItem {
    padding: 16px;
    min-height: auto;
  }

  .jobItem>div>div:first-child {
    margin-bottom: 24px;
  }

  .clearco-job-listing {
    height: auto !important;
  }

  .centercard {
    display: none;
  }

  .siteHeader_wrapper ul.navbar-nav li.nav-item:last-child {
    display: none;
  }

  .mobileSearch {
    display: block;
  }

  .artboardAnimation {
    display: none;
  }

  .grossary_terms {
    padding: 50px 0px;
  }

  .advantageBig_Mobile {
    display: block;
  }

  .card_advantage {
    opacity: unset;
    margin-bottom: 24px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  }

  .advantageBig_Circle {
    display: none;
  }

  .branding-tool {
    padding-top: 30px !important;
  }


  /* mobile common Class  start */
  .MobSpace_mb12 {
    margin-bottom: 12px;
  }

  .MobSpace_mb16 {
    margin-bottom: 16px;
  }

  .MobSpace_mb34 {
    margin-bottom: 34px;
  }

  .MobSpace_mb24 {
    margin-bottom: 24px;
  }

  .DeskFlex_Mobileblock {
    display: block;
  }

  .mobileFlexdirRow_reverse {
    flex-direction: row-reverse;
  }

  .mobileFlexdircolumn_reverse {
    flex-direction: column-reverse;
  }

  .padding_TopBottom80 {
    padding: 40px 0px;
  }

  /* mobile common Class  end */

  /* mobile Header Responsive start */
  .mobileHeader {
    padding: 12px 24px;
    border-bottom: 1px solid #d4d1d1;
    background: #080B36;
  }

  .siteHeader_wrapper {
    box-shadow: -.64px -.77px 32px #0000000d;
  }

  .siteHeader_wrapper .container {
    max-width: none;
  }

  .MobileToggle {
    display: block;
  }

  .mobileMenu {
    display: none;
  }

  .mobileMenu.showMenu {
    display: block;
  }

  .topHeader {
    display: none;
  }

  .topHeader .container>div {
    display: block;
  }

  ul.topHeaderMenulist li a {
    margin-right: 16px;
  }

  .siteHeader_wrapper {
    padding: 0px;
  }

  .siteHeader_wrapper .pli_brandLogo img {
    width: 70px;
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(346deg) brightness(105%) contrast(103%);
  }


  .siteHeader_wrapper nav {
    display: block !important;
    padding: 0px !important;
  }

  .siteHeader_wrapper ul.navbar-nav {
    display: block;
  }

  .siteHeader_wrapper ul.navbar-nav li a {
    margin: 0px;
    padding: 12px 24px !important;
    border-bottom: 1px solid #f9f9f9;
    color: #777;
    font-weight: 500;
  }

  .siteHeader_wrapper ul.navbar-nav li.nav-item.dropdown:hover ul.dropdown-menu {
    display: none;

  }

  .siteHeader_wrapper ul.navbar-nav li.dropdown ul.dropdown-menu {
    box-shadow: unset !important;
    border-top: unset !important;
  }

  .siteHeader_wrapper ul.navbar-nav .dropdown-toggle::after {
    position: absolute;
    right: 20px;
    top: 50%;
  }

  .siteHeader_wrapper ul.navbar-nav li.nav-item.dropdown {
    display: block;
  }

  .siteHeader_wrapper ul.dropdown-menu.show {
    display: block !important;
  }



  .MobileToggle button span {
    width: 32px;
    background: #FFFFFF;
    height: 3px;
    display: block;
    margin-top: 5px;
    -webkit-transition: all 600ms linear 0s;
    -o-transition: all 600ms linear 0s;
    transition: all 600ms linear 0s;
  }

  .MobileToggle button:hover span:nth-child(2) {
    width: 20px;
  }

  /* mobile Header Responsive end */


  .sectionheader {
    width: 100%;
    padding-bottom: 40px;
  }

  .sectionheader h2 {
    font-size: 26px;
  }

  .homeBnr_wrapper .row {
    flex-direction: column-reverse;
  }

  /* .bnr_Linegrp{
  display: none;
} */

  .bnr_Linegrp::after {
    /* width: 100%;
    height: 300px;
    background-position: right; */
    display: none;
  }

  .homeBnr_wrapper .bannerContent h1 {
    font-size: 28px;
  }

  .homeBnr_wrapper .blogSection::before {
    width: 100%;
  }

  .textureArrow::before {
    content: unset;
  }

  .siteVideoSec iframe {
    width: 100%;
    height: 260px;
  }

  .advantageBig_Circle {
    width: 250px;
    height: 250px;
    border: 2px dashed #080B36;
  }

  .advantageBig_Circle::before {
    width: 130px;
    height: 130px;
  }

  .advantage_groupCard .adv_groupCard_one,
  .advantage_groupCard .adv_groupCard_two {
    width: 70px;
  }

  .advantage_groupCard img.adv_groupCard_one {
    transform: rotate(18deg) translate(30px, -30px);

  }

  .ipadFrame img {
    transform: unset;
    top: unset;
    bottom: 0px;
    width: 100%;
    left: 0px;
  }

  /* .achievementRectangle {
    display: none;
  } */
  .customChartFrame {
    background: unset;
  }

  .customChartFrame {
    height: auto;
  }

  .customChartFrame>div:first-child {
    display: none;
  }

  .square {
    display: none;
  }

  .StepFrame {
    display: block;
  }

  .StepFrame>div {
    margin-bottom: 24px;
  }

  .StepFrame>div:last-child {
    margin-bottom: 0px;
  }

  .StepFrame .itemCol:nth-child(3)>div {
    text-align: center !important;
    width: 100%;
    padding: 0px;
  }

  .StepFrame .itemCol:nth-child(4)>div {
    width: 100%;
    padding: 0px;
  }

  .StepFrame .itemCol:nth-child(5)>div {
    width: 100%;
    padding: 0px;
  }

  .blogLeftSpace {
    padding: 0px 0px 34px;
  }

  .artboardAnimation::before {
    right: 25px;
    bottom: -20px;
  }

  .homeBnr_wrapper {
    padding: 40px 0px 40px;
    /* margin-top: 50px; */
  }

  .ipadFrame::after {
    top: -160px;
  }

  .bnr_groupCard .cardOne,
  .bnr_groupCard .cardTwo {
    width: 120px;
  }

  .bnr_groupCard img.cardTwo {
    transform: rotate(15deg) translate(-15px, 50px);
  }

  .hero__btn {
    display: none;
  }

  .bnr_groupCard {
    margin-bottom: 150px;
  }

  .pli_capabilities {
    padding: 40px 0px;
  }

  .pli_Advantages {
    padding: 40px 0px;
  }

  .achievementsSofar_content {
    padding: 40px 0px;
  }


  .font_Size24 {
    font-size: 18px;
  }

  .font_Size28 {
    font-size: 22px;
  }

  .font_Size30 {
    font-size: 24px;
  }

  .font_Size32 {
    font-size: 26px;
  }

  .font_Size34 {
    font-size: 28px;
  }

  .font_Size50 {
    font-size: 40px;
  }

  .card_advantage p {
    font-size: 14px;
  }

  .achievements_sofar::before {
    height: 100%;
  }

  .siteVideoSec {
    margin: 40px 0px;
    height: auto;
  }

  .connectedWith_pli::before {
    content: unset;
  }

  .connectedWith_pli {
    padding: 40px 0px;
    margin: 0px;
  }

  .industries_we_specialize {
    background: #F5F5F5;
    padding: 40px 0px 40px;
    margin-bottom: 0px;
  }

  .equalHeight {
    height: auto;
    padding-bottom: 40px;
  }


  .plims_customized {
    padding: 40px 0px;
    margin: 40px 0px;
    height: auto;
  }

  .siteFooter_wrapper {
    background: #0C0E2C;
    padding: 40px 0px;
  }

  .industSpecialize_videoFrame video {
    width: 100%;
    height: 250px;
  }

  .industSpecialize_Card img {
    width: 80px;
  }

  .industSpecialize_videoFrame {
    right: 0px;
    top: 0px;
  }

  .industSpecialize_videoFrame::after {
    content: unset;
  }

  ul.carousel_indicator {
    flex-wrap: wrap;
  }

  ul.carousel_indicator li a:before,
  .ul.carousel_indicator li a:after {
    content: unset !important;
  }

  ul.carousel_indicator li a:after {
    content: unset;
  }

  ul.carousel_indicator li {
    width: unset;
  }

  ul.carousel_indicator li a {
    padding: 0px;
  }

  .blogSection .owl-theme .owl-nav {
    bottom: -80px;
    left: 0px;
    top: unset;
    right: 0px;
  }

  .capabilitiesRow {
    padding-left: 16px;
    padding-bottom: 30px;
    overflow-x: auto;
  }

  .capabilitiesItem:nth-child(even) {
    margin-top: 0px;
  }

  .scroll-container {
    display: none;
  }

  .connectedWithForm {
    padding-left: 0px;
  }

  .connectedWith_leftImg {
    background: unset;
    display: none;
    top: 0px;
  }

  .progress-wrap {
    right: 30px;
    bottom: 30px;
  }

  .blogCard_Frame .blogCard_img img {
    min-height: 300px;
    max-height: 300px;
  }

  .industSpecialize_Card {
    left: 10px;
  }

  .capabilities_img img {
    height: 160px !important;
  }

  .capabilitiesItem {
    min-height: 360px;
  }

  .submitArtWorkFrameHeading {
    padding: 16px;
  }

  .submitArtWorkFrame_body {
    padding: 40px 24px 40px;
  }

  .otherpages_bnr {
    padding: 80px 0px 40px;
  }

  .artwork_ConditionsTab {
    bottom: 0px;
    margin-top: 30px;
  }

  .artwork_ConditionsTab ul li {
    margin-bottom: 12px;
  }

  .artwork_ConditionsTab ul li a,
  .artwork_ConditionsTab ul li button {
    font-size: 14px;
    padding: 8px 12px;
    width: 100%;
    display: block;
    text-align: center;
  }

  .artwork_ConditionsTab ul {
    display: block;
  }

  .blogSection::before {
    width: 100%;
  }

  .industries_brn .row {
    flex-direction: column-reverse
  }

  .products_brn .row {
    flex-direction: column-reverse
  }

  .whoweare_bnr .row {
    flex-direction: column-reverse
  }

  .capabilites_bnr .row {
    flex-direction: column-reverse
  }

  .industriesVideo_Frame video {
    height: 357px;
  }

  .capabilites_bnr {
    padding: 40px 0px;
  }

  .industries_brn {
    padding: 40px 0px;
  }

  .products_brn {
    padding: 40px 0px;
  }

  .whoweare_bnr {
    padding: 40px 0px;
  }

  .industriesCard img,
  .careerCard img {
    left: 20px;
    width: 100px;
  }

  .capabilites_bnr h1 {
    font-size: 24px;
  }

  .industries_brn h1 {
    font-size: 24px;
  }

  .products_brn h1 {
    font-size: 24px;
  }

  .whoweare_bnr h1 {
    font-size: 24px;
  }

  .violetVerbena_bg {
    padding: 40px 0px 40px;
  }

  /* .VerticalSteps {
    display: block;
} */
  .VerticalStepsWrapper .VerticalSteps:nth-child(odd) {
    flex-direction: column-reverse;
  }

  .VerticalStepsWrapper .VerticalSteps:nth-child(even) {
    flex-direction: column;

  }

  .VerticalSteps::after {
    content: "";
  }

  .VerticalSteps>div:first-child {
    text-align: left;
    margin-left: unset;
  }

  /* withOutFlex columns */


  /* .VerticalStepsWrapper .VerticalSteps:nth-child(odd)>div:first-child {
    padding: 0px 0px 0px;
}
.VerticalStepsWrapper  .VerticalSteps:nth-child(odd) >div:nth-child(3) {
    padding: 40px 0px 0px;
}
.VerticalStepsWrapper  .VerticalSteps:nth-child(even) >div:first-child {
    padding: 40px 0px 40px;
}
.VerticalStepsWrapper .VerticalSteps:nth-child(even)>div:nth-child(3) {
    padding: 0px 0px 40px;
} */

  /* withFlex columns */

  .VerticalStepsWrapper .VerticalSteps:nth-child(odd)>div:nth-child(3) {
    padding: 0px 0px 0px;
  }

  .VerticalStepsWrapper .VerticalSteps:nth-child(even)>div:first-child {
    padding: 20px 0px 0px;
  }

  .VerticalStepsWrapper .VerticalSteps:nth-child(even)>div:nth-child(3) {
    padding: 0px 0px 0px;
  }

  .VerticalStepsWrapper .VerticalSteps:nth-child(odd)>div:first-child {
    padding: 0px 0px 0px;
  }

  .centerStroke {
    margin: 0px;
    border-left: unset;
  }

  .centerStroke::before {
    content: unset;
  }

  .centerStroke::after {
    content: unset;
    /* left: 0px; */
    /* top: 0px; */
    /* position: static; */
    /* margin: 16px 0px; */
  }

  .readymadeSol_frame::after {
    content: unset;
  }

  .readymadeSol_frame {
    background: #080b36;
    height: auto;
    border-radius: 15px;
  }

  .readyMade_Solution {
    padding: 0px 0px 80px;
  }

  .readymadeSol_frame {
    padding: 24px;
  }

  .capabilites_Frame .capabilites_img img {
    min-height: 250px;
    max-height: 250px;
  }

  .capabilitesItem .capabilites_Frame::before {
    top: 100px;
  }

  .sustainability_bnr {
    height: auto;
    padding: 40px 0px;
  }

  .sustainability_bnr .row {
    flex-direction: column-reverse;
  }

  .Meets_durability_Excellence {
    padding: 40px 0px;
  }

  .susabilityMeet_items {
    height: auto;
    padding: 34px 24px;
  }

  .ecoFocu_oprtion_leftImg {
    height: 380px;
    position: static !important;
  }

  .ecoFocusOper_img {
    margin-left: 0px;
  }

  .ourEcoFocused_Operations {
    padding: 40px 0px;
  }

  .sustainability_bnr .row {
    flex-direction: column-reverse;
  }

  .Meets_durability_Excellence {
    padding: 40px 0px;
  }

  .susabilityMeet_items {
    height: auto;
    padding: 34px 24px;
  }

  .ecoFocu_oprtion_leftImg {
    height: 380px;
    position: static !important;
  }

  .ecoFocusOper_img {
    margin-left: 0px;
  }

  .ourEcoFocused_Operations {
    padding: 40px 0px;
  }

  .contactusFrame {
    padding: 16px;
  }

  .contactForm_whiteFrame {
    padding: 16px;
  }

  .contactusFrame::before {
    content: unset;
  }

  ul.contact_detail {
    width: 100%;
    margin-bottom: 24px;
  }

  .capabilities_Bnr_Frame img.capabilities_bnr_img {
    width: 100%;
    height: 363px;
    border-radius: 160px 0px 0px 0px;
  }

  .CapabilitesProductCaroucel {
    /* height: 780px; */
    /* height: 550px; */
    margin-top: 40px;
  }

  .Capabilites_displays_carousel {
    /* height: 780px; */
    height: 473px !important;
  }

  .CapabilitesProductCaroucel .carousel-content,
  .Capabilites_displays_carousel .carousel-content {
    width: 100%;
  }


  .unveiling_Ideal_Substrate {
    padding: 40px 0px;
    margin-bottom: 0px;
  }

  .paperBeyond_leftImg {
    position: static !important;
    margin-bottom: 34px;
  }

  .elevateBrand_newHeights_leftImg {
    position: static !important;
    height: 330px;
  }

  .elevateBrand_newHeights_leftImg {
    position: static !important;
    height: 480px;
  }

  .marginLeft25 {
    margin-left: 0px !important;
  }

  .ecoFocus_operationItem {
    flex-direction: column;
    text-align: center;
    transform: unset !important;
  }

  .leadershipTeam {
    padding: 50px 0px;
  }

  .choices_everyVision {
    padding: 0px 0px 40px;
  }

  .icyLilac_bgauto {
    padding: 24px;
  }

  .tailored_pkg_occasion_leftImg {
    position: static !important;
    background-size: contain;
    /* height: 330px; */
    margin-bottom: 34px;
  }

  .gift_extra_touch {
    padding: 0px 0px 0px;
  }

  .displays_Solution_bg {
    padding: 40px 0px 40px;
  }

  .emp_driven_and_funded {
    padding: 0px 0px 40px;
  }

  .employeeFunded_leftImg img {
    position: static !important;
    height: 310px;
    background-size: contain;
  }

  .charity_pli_coreValue {
    padding: 40px 0px 0px;
  }

  .centercard {
    width: 280px;
    height: 280px;
  }

  .centercard img.centercard_two {
    transform: rotate(12deg) translate(-92px, 0px);
    top: 50px;
  }

  .centercard img {
    width: 120px;
  }

  .benefits_sec {
    padding: 40px 0px;
  }

  .job_Openings {
    padding: 40px 0px;
  }

  .meetOur_team_sec {
    padding: 0px 0px 40px;
  }

  .joinUs_rectangle::after {
    content: unset;
  }

  .career_belowSpace {
    padding: 0px 0px 40px;
  }

  .look_inside {
    margin: 0px 0px 40px;
  }

  .mediaObjects {
    display: block;
  }

  .mediaObjects_header {
    display: none;
  }

  .mediaObjects_body {
    width: 100%;
    height: auto;
    margin-left: 0px;
    padding: 30px;
  }

  .empSpotlight_tab {
    padding-bottom: 40px;
  }

  .mediaObjects_body::before {
    transform: translate(-10px, -60px);
    width: 75px;
    height: 75px;
  }

  .mediaObjects_body::after {
    transform: translate(10px, 50px);
  }

  .employeesSpotlight::after {
    top: 22%;
  }

  .capabilities_equipment {
    padding: 0px 0px 40px;
  }

  .how_we_achieveit::after {
    content: unset;
  }

  .please_visit_us {
    padding: 40px 0px;
  }

  /* .visit_Item img{
    min-height: 180px;
    max-height: 180px;
} */
  .mission_visionSec {
    padding: 40px 0px 0px;
  }

  .mission_VisionItem {
    min-height: auto;
  }

  .pliDifference {
    padding: 40px 0px 0px;
  }

  .leadershipTeam_item {
    margin-bottom: 34px;
  }

  .benefits_Item {
    min-height: auto;
  }

  .industries_we_specialize #sync2 h5 {
    display: none;
  }

  .sitemap {
    padding: 50px 20px !important;
  }

  .min_height205,
  .min_height212 {
    min-height: unset !important;
  }

  .tableResponsive {
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
    overflow-y: hidden;
  }

  /* width */
  .tableResponsive::-webkit-scrollbar {
    width: 6px;
    height: 8px;
    border-radius: 5px;
  }

  /* Track */
  .tableResponsive::-webkit-scrollbar-track {
    background: #e5e7eb;
  }

  /* Handle */
  .tableResponsive::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;

  }

  /* Handle on hover */
  .tableResponsive::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .termsofuse_bnr {
    height: 250px !important;
  }

  .homeIndust_img img {
    width: 100%;
    height: auto;
    object-fit: unset;
  }

  .how_we_achieveit_item {
    min-height: auto;
  }

  .ready_get_started {
    margin: 40px 0px;
  }

  .fulfillment_item {
    padding: 40px 0px 0px;
  }

  .whoweare_Bnr_Frame img.ww_are_bnr {
    height: 338px;
    padding: 12px 0px 12px 12px;
    border-radius: 70.694px 0px;
  }

  .comprehensiveFirst_img img {
    width: 100% !important;
  }

  h2.pageText {
    font-size: 34px;
  }

  .newsroom_bnr {
    height: 280px;
  }

  .how_we_achieveit {
    padding: 40px 0px 40px;
  }

  .margin_Bottom50 {
    margin-bottom: 30px !important;
  }

  .certifications_Item img {
    width: 100%;
  }

  .career_up_animation {
    display: none;
  }

  .infNight_imgOutline::after {
    left: 10px;
    top: 10px;
  }

  .phaserBeam_imgOutline::after {
    left: 10px;
    top: 10px;
  }

  .indust_bnr_animation {
    display: none;
  }

  .loyalty_banner_animation,
  .sustainability_animation {
    display: none;
  }

  .comprehensive_img {
    padding-top: 0px;
  }

  .homeBnr_wrapper .col-lg-7 {
    display: none;
  }

  .termsofuse_bnr .container>div {
    width: 100%;
  }

  .termsofuse_wrapper {
    padding: 40px 0;
  }

  .privacypolicy_wrapper {
    padding: 40px 0;
  }

  .privacypolicy_bnr .container>div {
    width: 100%;
  }

  .privacypolicy_bnr {
    height: 250px;
  }

  .accessbility_bnr {
    height: 250px;
  }

  .accessbility_bnr .container>div {
    width: 100%;
  }

  .accessbility_wrapper {
    padding: 40px 0px;
  }

  .searchBnr {
    height: 250px;
  }

  .mfp-arrow-right:after {
    border-left: 17px solid #FF4B00 !important;
  }

  .mfp-arrow-left:after {
    border-right: 17px solid #FF4B00 !important;
  }

  .reliability_Sea_img {
    width: 100% !important;
    padding-top: 0px;
  }

  .diverseRange_EntertainmentTypes {
    padding-top: 0px;
  }

  .newsroom_wrapper {
    padding: 80px 0px 40px;
  }
    .giftcardFraud_prevention_bnr .row {
      flex-direction: column-reverse;
    }

    .giftcardFraud_prevention_bnr {
      padding: 40px 0px 0px;
      height: auto;
    }

    .interesting_Facts {
      padding: 40px 0px;
    }

    .securityLabels_sec {
      padding: 0px 0px 40px;
    }

    .packaging_gcfp {
      padding: 0px 0px 40px;
    }

    .coldGlueTechnique_sec {
      padding: 0px 0px 40px;
    }

    .pluggedIn_sec {
      padding: 0px 0px 40px;
    }

    .pluggedIn_borderFrame {
      width: 100%;
      padding: 24px 20px;
      border-radius: 30px;
    }

    .pluggedIn_Rectangle {
      border-radius: 20px;
      padding: 16px;
    }

    .pluggedIn_borderFrame::before {
      width: 60px;
      background-size: 50px;
      left: 30px;
    }

    .pluggedIn_borderFrame::after {
      width: 60px;
      background-size: 50px;
      right: 30px;
      bottom: -30px;
    }

    .interestingFacts_frame {
      padding: 16px;
    }
}






@media (min-width: 768px) and (max-width: 992px) {
  .homeBnr_wrapper .row {
    flex-direction: column-reverse;
  }

  .ipadFrame img {
    left: 0px;
    top: -180px !important;
  }

  .textureArrow::before {
    content: unset !important;
  }

  .ipadFrame::after {
    top: unset !important;
    bottom: 70px;
  }

  .bnr_groupCard {
    margin-bottom: 180px;
  }

  .homeBnr_wrapper {
    padding: 90px 0px 100px;
  }


  .connectedWith_leftImg {
    background: unset;
    top: 0px;
  }

  .scroll-container {
    display: none;
  }

  .sustainability_bnr {
    height: auto;
    padding: 40px 0px;
  }

  .sustainability_bnr .row {
    flex-direction: column-reverse;
  }

  .Meets_durability_Excellence {
    padding: 40px 0px;
  }

  .susabilityMeet_items {
    height: auto;
    padding: 34px 24px;
  }

  .ecoFocu_oprtion_leftImg {
    height: 380px;
    position: static !important;
  }

  .ecoFocusOper_img {
    margin-left: 0px;
  }

  .ourEcoFocused_Operations {
    padding: 40px 0px;
  }

  .contactusFrame {
    padding: 16px;
  }

  .contactForm_whiteFrame {
    padding: 16px;
  }

  .contactusFrame::before {
    content: unset;
  }

  ul.contact_detail {
    width: 100%;
    margin-bottom: 24px;
  }

  .marginLeft25 {
    margin-left: 0px !important;
  }

  .comprehensive_img {
    padding-top: 0px;
  }

  .sitemap {
    padding: 50px 20px !important;
  }

  .min_height205,
  .min_height212 {
    min-height: unset !important;
  }

  .whoweare_Bnr_Frame img.ww_are_bnr {
    height: 700px;
    padding: 16px 0px 16px 16px;
    border-radius: 140.694px 0px;
  }

  .whoweare_bnr .row {
    flex-direction: column-reverse;
  }

  .comprehensiveFirst_img img {
    width: 100% !important;
  }
.giftcardFraud_prevention_bnr .row {
  flex-direction: column-reverse;
}

.giftcardFraud_prevention_bnr {
  padding: 40px 0px 0px;
  height: auto;
}

.interesting_Facts {
  padding: 40px 0px;
}

.securityLabels_sec {
  padding: 0px 0px 40px;
}

.packaging_gcfp {
  padding: 0px 0px 40px;
}

.coldGlueTechnique_sec {
  padding: 0px 0px 40px;
}

.pluggedIn_sec {
  padding: 0px 0px 40px;
}

.pluggedIn_borderFrame {
  width: 100%;
  padding: 24px 20px;
  border-radius: 30px;
}

.pluggedIn_Rectangle {
  border-radius: 20px;
  padding: 16px;
}

.pluggedIn_borderFrame::before {
  width: 60px;
  background-size: 50px;
  left: 30px;
}

.pluggedIn_borderFrame::after {
  width: 60px;
  background-size: 50px;
  right: 30px;
  bottom: -30px;
}

.interestingFacts_frame {
  padding: 16px;
}

}

@media (min-width: 768px) and (max-width: 1026px) {

  /* mobile common Class  start */
  .MobSpace_mb12 {
    margin-bottom: 12px;
  }

  .MobSpace_mb16 {
    margin-bottom: 16px;
  }

  .MobSpace_mb34 {
    margin-bottom: 34px;
  }

  .MobSpace_mb24 {
    margin-bottom: 24px;
  }

  .DeskFlex_Mobileblock {
    display: block;
  }

  .mobileFlexdirRow_reverse {
    flex-direction: row-reverse;
  }

  .mobileFlexdircolumn_reverse {
    flex-direction: column-reverse;
  }

  .padding_TopBottom80 {
    padding: 40px 0px;
  }

  /* mobile common Class  end */

  /* mobile Header Responsive start */
  .mobileHeader {
    padding: 12px 24px;
    /* box-shadow: -.64px -.77px 32px #0000000d; */
    border-bottom: 1px solid #d4d1d1;
    background: #080B36;
  }

  .siteHeader_wrapper {
    box-shadow: -.64px -.77px 32px #0000000d;
  }

  .siteHeader_wrapper .container {
    max-width: none;
    padding: 0px;
  }

  .MobileToggle {
    display: block;
  }

  .mobileMenu {
    display: none;
  }

  .mobileMenu.showMenu {
    display: block;
  }

  .topHeader {
    display: none;
  }

  .topHeader .container>div {
    display: block;
  }

  ul.topHeaderMenulist li a {
    margin-right: 16px;
  }

  .siteHeader_wrapper {
    padding: 0px;
  }

  .siteHeader_wrapper .pli_brandLogo img {
    width: 70px;
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7500%) hue-rotate(346deg) brightness(105%) contrast(103%);
  }

  .siteHeader_wrapper nav {
    display: block !important;
    padding: 0px !important;
  }

  .siteHeader_wrapper ul.navbar-nav {
    display: block;
  }

  .siteHeader_wrapper ul.navbar-nav li a {
    margin: 0px;
    padding: 12px 24px !important;
    border-bottom: 1px solid #f9f9f9;
    color: #777;
    font-weight: 500;
  }

  .siteHeader_wrapper ul.navbar-nav li.nav-item.dropdown:hover ul.dropdown-menu {
    display: none;

  }

  .siteHeader_wrapper ul.navbar-nav li.dropdown ul.dropdown-menu {
    box-shadow: unset !important;
    border-top: unset !important;
  }

  .siteHeader_wrapper ul.navbar-nav .dropdown-toggle::after {
    position: absolute;
    right: 20px;
    top: 50%;
  }

  .siteHeader_wrapper ul.navbar-nav li.nav-item.dropdown {
    display: block;
  }

  .siteHeader_wrapper ul.dropdown-menu.show {
    display: block !important;
  }



  .MobileToggle button span {
    width: 32px;
    background: #FFFFFF;
    height: 3px;
    display: block;
    margin-top: 5px;
    -webkit-transition: all 600ms linear 0s;
    -o-transition: all 600ms linear 0s;
    transition: all 600ms linear 0s;
  }

  .MobileToggle button:hover span:nth-child(2) {
    width: 20px;
  }

  /* mobile Header Responsive end */
  .bnr_Linegrp::after {
    z-index: -1;
  }

  .bnr_groupCard .cardOne,
  .bnr_groupCard .cardTwo {
    width: 130px;
  }

  .bnr_groupCard img.cardTwo {
    transform: rotate(15deg) translate(-13px, 40px);
  }

  .ipadFrame img {
    top: 70px;
    width: 100%;
    left: 0px;
    transform: translate(0px, 0px);
  }

  .hero__btn {
    display: none;
  }

  .textureArrow::before {
    /* content: unset; */
    top: 11px;
  }

  .homeBnr_wrapper .bannerContent h1 {
    font-size: 34px;
  }

  .homeBnr_wrapper {
    padding: 90px 0px 100px;
  }

  .pli_capabilities {
    padding: 40px 0px;
  }

  .sectionheader {
    width: 100%;
  }

  .capabilitiesItem:nth-child(even) {
    margin-top: 0px;
  }

  .capabilitiesRow {
    padding-left: 0px;
    padding-bottom: 50px;
  }

  .pli_Advantages {
    padding: 40px 0px;
  }

  .achievementsSofar_content {
    padding: 40px 0px;
  }

  .achievementRectangle {
    display: none;
  }

  .achievements_sofar::before {
    height: 100%;
  }

  .siteVideoSec iframe {
    width: 100%;
    height: auto;
  }

  .siteVideoSec {
    margin: 40px 0px;
    height: auto;
  }

  .equalHeight {
    height: auto;
  }

  .industries_we_specialize {
    padding: 40px 0px;
    margin-bottom: 40px;
  }

  .connectedWith_pli {
    padding: 40px 0px;
    margin-bottom: 40px;
  }

  .connectedWith_leftImg {
    top: 0px;
  }

  .connectedWithForm {
    padding-left: 0px;
  }

  .blogSection::before {
    width: 100%;
  }

  .blogLeftSpace {
    padding: 0px 0px 50px 0px;
  }

  .plims_customized {
    height: auto;
    padding: 40px 0px;
    margin: 40px 0px;
  }

  .siteFooter_wrapper {
    background: #0C0E2C;
    padding: 40px 0px;
  }

  .industSpecialize_videoFrame {
    margin-bottom: 0px;
    right: 0px;
    top: 0px;
  }

  .industSpecialize_videoFrame video {
    margin-left: unset;
    width: 100%;
    height: 280px;
  }

  .industSpecialize_Card img {
    width: 100px;
  }

  ul.carousel_indicator li {
    width: unset;
  }

  ul.carousel_indicator li a:before,
  ul.carousel_indicator li a::after {
    content: unset !important;
  }

  .blogSection .owl-theme .owl-nav {
    left: 0px;
    top: unset;
    bottom: -70px;
    right: 0px;
  }

  .industSpecialize_Card {
    bottom: -30px;
    left: -50px;
  }

  .submitArtWorkFrame_body {
    padding: 80px 30px 40px;
  }

  .otherpages_bnr {
    padding: 80px 0px 40px;
  }

  .scroll-container {
    width: 160px;
    height: 160px;
  }

  .font_Size34 {
    font-size: 28px;
  }

  .VerticalStepsWrapper .VerticalSteps:nth-child(odd)>div:nth-child(3) {
    padding: 50px 0px 50px;
  }

  .VerticalStepsWrapper .VerticalSteps:nth-child(even)>div:first-child {
    padding: 50px 0px 50px;
  }

  .leadershipTeam_item {
    width: 100%;
  }

  .sitemap {
    padding: 50px 20px !important;
  }

  .comprehensiveFirst_img img {
    width: 100% !important;
  }

}

@media (min-width: 1027px) and (max-width: 1229px) {

  .siteHeader_wrapper .pli_brandLogo img {
    width: 70px;
  }

  .siteHeader_wrapper ul.navbar-nav li a {
    font-size: 12px;
    margin-right: 16px;
    padding: 12px 8px;
  }

  .siteHeader_wrapper {
    padding: 12px 0px;
  }

  .homeBnr_wrapper .bannerContent h1 {
    font-size: 34px;
  }

  .bnr_groupCard .cardOne,
  .bnr_groupCard .cardTwo {
    width: 140px;
  }

  .bnr_groupCard img.cardTwo {
    transform: rotate(15deg) translate(-13px, 40px);
  }

  .textureArrow::before {
    top: 12px;
  }

  .ipadFrame img {
    /* width: 554px; */
    top: 60px;
    transform: unset;
    left: 0px;
    transform: unset;
    /* transform: translate(-30%, 0px); */
  }

  .homeBnr_wrapper {
    padding: 80px 0px 120px;
  }

  .sectionheader h2 {
    font-size: 28px;
  }

  .sectionheader {
    padding-bottom: 40px;
  }

  .font_Size34 {
    font-size: 24px;
  }

  .font_Size16 {
    font-size: 14px;
  }

  .font_Size50 {
    font-size: 35px;
  }



  .font_Size14 {
    font-size: 12px;
  }

  .font_Size20 {
    font-size: 18px;
  }

  .font_Size24 {
    font-size: 18px;
  }

  .padding_TopBottom80 {
    padding: 40px 0px;
  }

  .pli_capabilities {
    padding: 80px 0px;
  }

  .pli_Advantages {
    padding: 40px 0px;
  }

  .achievementsSofar_content {
    padding: 40px 0px 0px;
  }

  .customChartFrame {
    height: 416px;
    /* height: 526px; */
  }

  .siteVideoSec {
    margin: 40px 0px;
    height: auto;
  }

  .industries_we_specialize {
    padding: 40px 0px 40px;
    margin-bottom: 40px;
  }

  .industSpecialize_videoFrame video {
    width: 100%;
    height: 260px;
  }

  .industSpecialize_videoFrame::after {
    width: 100%;
  }

  .industSpecialize_Card img {
    width: 120px;
  }

  .industSpecialize_Card {
    position: absolute;
    bottom: -30px;
    left: -30px;
  }

  .equalHeight {
    height: 360px;
  }

  ul.carousel_indicator li a {
    font-size: 12px;
  }

  ul.carousel_indicator li a::first-letter {
    font-size: 14px;
  }

  .blogLeftSpace {
    padding: 0px 0px 40px 0px;
  }

  .blogCard_Frame .blogCard_img img {
    min-height: 280px;
    max-height: 280px;
  }

  .blogCard_caption h3 {
    font-size: 12px;
  }

  .blogCard_caption {
    padding: 0px 16px;
  }

  .plims_customized {
    padding: 40px 0px;
    margin: 40px 0px 0px;
    height: auto;
  }

  .scroll-container {
    width: 230px;
    height: 300px;
  }

  .capabilitiesItem:nth-child(even) {
    margin: 0px;
  }

  .capabilitiesItem {
    min-height: 350px;
    width: 100%;
    min-width: 280px;
  }

  .capabilitiesRow {
    padding-left: 0px;
    padding-bottom: 40px;
  }

  .StepFrame .itemCol:nth-child(3)>div {
    padding-top: 20px;
    width: 100%;
  }

  .StepFrame .itemCol:nth-child(4)>div {
    padding-top: 110px;
    width: 100%;
    text-align: left;
  }

  .StepFrame .itemCol:nth-child(5)>div {
    padding-top: 155px;
    width: 100%;
  }

  .siteVideoSec iframe {
    border-radius: 30px;
    width: 80%;
    height: 400px;
  }

  .connectedWith_leftImg {
    top: 0px;
  }

  .connectedWith_pli {
    padding: 40px 0px;
    margin-bottom: 40px;
  }

  .connectedWithForm {
    padding-left: 30px;
  }

  .capabilities_img img {
    height: 160px;
  }

  .leadershipTeam_item {
    width: 100%;
  }


}

@media (min-width: 1200px) and (max-width: 1229px) {
  .ipadFrame img {
    top: 30px !important;
  }

  .customChartFrame {
    height: 522px;
  }

  .StepFrame .itemCol:nth-child(5)>div {
    padding-top: 240px;
    width: 100%;
    text-align: left;
  }

  .StepFrame .itemCol:nth-child(4)>div {
    padding-top: 170px;
    width: 100%;
  }

  .StepFrame .itemCol:nth-child(3)>div {
    padding-top: 60px;
  }
}

@media (min-width: 1230px) and (max-width: 1396px) {
  .siteHeader_wrapper ul.navbar-nav li a {
      font-size: 14px;
      margin-right: 12px;
    }
  .ipadFrame img {
    /* width: 700px; */
    top: 75px;
    transform: scale(1.1);
    left: -10px;
    /* transform: translate(-30%, 0px); */
  }

  .homeBnr_wrapper {
    padding: 120px 0px;
  }

  .pli_capabilities {
    padding: 40px 0px;
  }

  .pli_Advantages {
    z-index: -3;
    padding: 60px 0px;
  }

  .achievementsSofar_content {
    padding: 60px 0px;
  }

  .customChartFrame {
    height: 530px;
  }

  .StepFrame .itemCol:nth-child(3)>div {
    padding-top: 20px;
    width: 100%;
  }

  .StepFrame .itemCol:nth-child(4)>div {
    padding-top: 130px;
    width: 200px;
    text-align: left;
  }

  .StepFrame .itemCol:nth-child(5)>div {
    padding-top: 200px;
    width: 200px;
  }

  .achievements_sofar {
    margin-bottom: 0px;
  }

  .siteVideoSec {
    margin: 60px 0px;
    height: auto;
  }

  .siteVideoSec iframe {
    height: 500px;
    width: 70%;
  }

  .industries_we_specialize {
    padding: 60px 0px;
    margin-bottom: 140px;
  }

  .connectedWith_pli {
    padding: 60px 0px;
    margin-bottom: 60px;
  }

  .padding_TopBottom80 {
    padding: 60px 0px;
  }

  .plims_customized {
    margin: 60px 0px;
  }

  .industSpecialize_Card {
    bottom: -30px;
    left: -20px;
  }


}

/* chart Responsive */
@media (min-width: 1396px) and (max-width: 1399px) {

  .customChartFrame {
    height: 530px;
  }

  .StepFrame .itemCol:nth-child(5)>div {
    padding-top: 200px;
  }

  .StepFrame .itemCol:nth-child(4)>div {
    padding-top: 150px;
    width: 100%;
    text-align: left;
  }

  .StepFrame .itemCol:nth-child(3)>div {
    padding-top: 0px;
  }
}

@media (min-width: 1400px) and (max-width: 1535px) {
  .ipadFrame img {
    width: 100%;
    top: 70px;
    transform: scale(1.1);
    left: 0px;
  }
}

@media screen and (-moz-min-device-pixel-ratio: 1.5) {
  /* Your styles for Firefox-specific query */
}


// @media (min-width: 1228px) and (max-width: 1400){
//     .siteHeader_wrapper ul.navbar-nav li a {
//         font-size: 14px;
//         margin-right: 12px;
//       }
// }





@keyframes marquee_animation1 {
  0% {
    transform: translateX(-10%);
  }

  100% {
    transform: translateX(-80%);
  }
}

/* Define the animation for right movement */
@keyframes marquee_animation2 {
  0% {
    transform: translateX(-80%);
  }

  100% {
    transform: translateX(-10%);
  }
}

.customerLogos {
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
}

.customerLogos>div {
  display: inline-block;
  margin-right: 10px;
  /* Adjust the margin as needed */
}

/* .customerLogos>div:first-child {
    animation: marquee_animation1 ease-in-out 15s 0s infinite;
  }

  .customerLogos>div:nth-child(2) {
    animation: marquee_animation2  ease-in-out 15s 0s infinite;
  } */

#cardBack,
#cardFront {
  opacity: 1;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
}

.industriesCard,
.careerCard {
  opacity: 0;
  z-index: 99;
}





body {
  position: relative;
}

.default-svg {
  position: absolute;
  top: 0;
  height: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}

.default-svg {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.default-svg svg {
  max-width: 100%;
  max-height: 100%;
}

.ballpath-svg {
  position: absolute;
  right: 0px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* z-index: 9999; */
}

.custom-svg {
  position: absolute;
  right: 0px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* z-index: 99999; */
  /* display: none; */
}

.custom-path1 {
  position: relative;
  /* stroke: red !important; */
}

/* .custom-path1,
.custom-path {
  display: none;
} */

/* .display_none{
  display: none;
} */





/* modal Style */


.leadershipPopup .modal-dialog {
  max-width: 800px;
  margin: 1.75rem auto;

}

.leadershipPopup .modal-content {
  border-radius: 20px;
  border-bottom-right-radius: 10px;
}

.leadershipPopup .modal-header {
  border-bottom: unset !important;
  padding: 0px;
  position: relative;
  margin: 0px;
}

.leadershipPopup .modal-body {
  padding: 0px 34px 34px;
  height: 460px;
  overflow-y: auto;
}

.leadershipPopup .modal-body::-webkit-scrollbar {
  background: #f4f5f7;
  border-radius: 30px;
  width: 8px;
  height: 6px;
}

.leadershipPopup .modal-body::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 30px;
}

.leadershipPopup .modal-body::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 30px;
  margin-top: 16px;
}

.leadershipPopup .close {
  margin: 0px 0 0px auto !important;
  padding: 1rem 1rem 0px !important;
  outline: unset;
}


/* giftingproducts_view */



.giftingproducts_view .modal-dialog {
  /* max-width: 800px; */
  margin: 1.75rem auto;

}

.giftingproducts_view .modal-content {
  border-radius: 20px;
  border-bottom-right-radius: 10px;
}

.giftingproducts_view .modal-header {
  border-bottom: unset !important;
  padding: 1rem 1rem 1rem;
  position: relative;
  margin: 0px;
}

.giftingproducts_view .modal-body {
  /* padding: 0px 34px 34px; */
  height: 600px;
  overflow-y: auto;
}

.giftingproducts_view .modal-body::-webkit-scrollbar {
  background: #f4f5f7;
  border-radius: 30px;
  width: 6px;
  height: 6px;
}

.giftingproducts_view .modal-body::-webkit-scrollbar-thumb:hover {
  background: #555;
  background: #777777;
  border-radius: 30px;
}

.giftingproducts_view .modal-body::-webkit-scrollbar-thumb {
  background: #777777;
  border-radius: 30px;
  margin-top: 16px;
}

.giftingproducts_view .close {
  margin: 0px 0 0px auto !important;
  padding: 0px !important;
  outline: unset;
}

.giftingproducts_viewgrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  flex-wrap: wrap;
  gap: 1rem;
}

.giftingproducts_viewgrid img {
  width: 260px;
  border: 1px solid rgba(8, 11, 54, 0.40);
  border-radius: 5px;
}






button.close:focus {
  outline: unset !important;
}

.block {
  position: relative;
}

.block-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.block-text {
  position: relative;
  z-index: 2;
  padding: 10rem;
}

.block-bg svg {
  fill: grey;
  width: 100%;
  height: 100%;
}

.colorOrange {
  border-top: 2px solid #FF4B00 !important;
  border: 2px dashed #FF4B00 !important;
}


.mfp-bg {
  background: #0b0b0b !important;
  opacity: .8 !important;
}

.mfp-figure {
  width: 500px;
  height: 545px;
    overflow-y: auto;
  background-color: #FFFFFF;
  border-radius: 10px;
}
.mfp-figure::-webkit-scrollbar {
  background: #f4f5f7;
  border-radius: 30px;
  width: 6px;
  height: 6px;
}

.mfp-figure::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 30px;
}

.mfp-figure::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 30px;
  margin-top: 16px;
}

.mfp-figure figure {
  // padding: 60px 24px 24px;
    display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
}

.mfp-bottom-bar {
  margin-top: 0px !important;
}

img.mfp-img {
  /* width: 450px !important;  */
  /* height: 450px !important; */
  /* object-fit: contain; */
padding: 40px 24px 40px !important;
}

.mfp-counter {
  color: #777;
}

.mfp-close-btn-in .mfp-close {
  color: #080B36 !important;
  right: 0 !important;
  padding-right: 12px !important;
}

.mfp-arrow-left:before {
  border-right: unset !important;
}

.mfp-arrow-right:before {
  border-left: unset !important;
}

.mfp-figure::after {
  content: '';
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444444;
}


.Capabilites_displays_carousel {
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/Capabilites_displays_Carousel_bg.jpg") no-repeat;
  background-size: contain;
  background-position: center;
  width: 100%;
  height: 633px;
  position: relative;
  margin-top: 80px;
}




























.margin_top_btm10 {
  margin: 10px 0px !important;
}

.termsofuse_bnr {
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/blog_bnr.jpg");
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  height: 470px;
}

.termsofuse_bnr .container>div {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 60%;
  margin: auto;
}

.termsofuse_wrapper {
  padding: 80px 0px;
}

.terms_textcontent li p {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  color: #444444;
  line-height: 1.5 !important;
}

.terms_textcontent li h4 {
  color: #252525 !important;
  font-size: 18px !important;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 20px;
  list-style: none;
}

.terms_textcontent li {
  list-style: none;
}

.black_text {
  color: #252525 !important;
}



.accessbility_bnr {
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/blog_bnr.jpg");
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  height: 470px;
}

.accessbility_bnr .container>div {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 60%;
  margin: auto;
}

.accessbility_wrapper {
  padding: 80px 0px;
}

.terms_textcontent a,
.terms_textcontent b {
  color: #FF4B00;
  text-decoration: none;
  font-weight: 600;
}




.privacypolicy_bnr {
  background: url("https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/blog_bnr.jpg");
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  height: 470px;
}

.privacypolicy_bnr .container>div {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 60%;
  margin: auto;
}

.privacypolicy_wrapper {
  padding: 80px 0px;
}


.align_item_Baseline {
  align-items: baseline;
}

.branding-tool {
  padding-top: 200px;


}


.comingSoon_text {
  position: relative;
  z-index: 999;
}

.comingSoon_text::after {
  content: "Coming Soon";
  background: #000;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0.5;
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}






/* .socialMedia_icon {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */

.socialMedia_iconl li {
  list-style: none;
}

.socialMedia_icon li a {
  width: 80px;
  height: 80px;
  background-color: #fff;
  text-align: center;
  line-height: 80px;
  font-size: 35px;
  margin: 0 10px;
  display: block;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  /* border: 3px solid #fff; */
  z-index: 1;
}

.socialMedia_icon li a img {
  position: relative;
  color: #262626;
  transition: .5s;
  z-index: 3;
}

.socialMedia_icon li a:hover img {
  color: #fff;
  transform: rotateY(360deg);
}

.socialMedia_icon li a:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f00;
  transition: .5s;
  z-index: 2;
}

.socialMedia_icon li a:hover:before {
  top: 0;
}

.socialMedia_icon li:nth-child(1) a:before {
  background: #3b5999;
}

.socialMedia_icon li:nth-child(2) a:before {
  background: #55acee;
  background: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
}

.socialMedia_icon li:nth-child(3) a:before {
  background: #0077b5;
}

.socialMedia_icon li:nth-child(4) a:before {
  background: #ff0000;
}


/* search start */



.closeSearch .searchInputGrptext {
  animation: closegrptext .5s;
  border-right: unset !important;
}

.closeSearch.searchInputGrp {
  display: inline-flex;
  position: absolute;
  right: 0px;
  bottom: 0px;
}

//  @keyframes mymove {
//   from {
//     width: 0px;
//   }

//   to {
//     width: 880px;
//   }
// }

// @keyframes closemove {
//   10% {
//     padding: 0 16px;
//     border: 1px solid #F5F5F5;
//     border-left: unset;
//   }

//   50% {
//     padding: 0 16px;
//     border: 1px solid #F5F5F5;
//     border-left: unset;
//   }

//   70% {
//     padding: 0 16px;
//     border: 1px solid #F5F5F5;
//     border-left: unset;
//   }
// }

// @keyframes closegrptext {
//   10% {
//     padding: 0 0 0 16px;
//     border: 1px solid #F5F5F5;
//     border-right: unset !important;
//   }

//   50% {
//     padding: 0 0 0 16px;
//     border: 1px solid #F5F5F5;
//     border-right: unset !important;
//   }

//   70% {
//     padding: 0 0 0 16px;
//     border: 1px solid #F5F5F5;
//     border-right: unset !important;
//   }
// }


.searchInputGrp input::placeholder {
  color: #777777;
}

.showSearch .searchInputGrptext {
  border-right: initial !important;
  border: 1px solid #FFFFFF;
  background: #FFFFFF;
  height: 100%;
  padding: 0 0 0 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.showSearch {
  display: inline-flex;
  position: absolute;
  right: 0px;
  left: 0;
  width: 100%;
  bottom: 0px;
  top: 0px;
  /* transform: translateY(-30%); */
}


.closeIcon {
  position: relative;
  z-index: 9999;
  background: #FFFFFF;
  padding: 0 12px 0 0px;
}

.showSearch input {
  border-left: unset !important;
  width: 100%;
  transition: .5s ease forwards;
  animation: mymove .5s !important;
  padding: 12px 16px;
  border: 1px solid #FFFFFF;
  background: #FFFFFF;
  outline: unset;
  color: #080B36;
  height: 100%;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  margin-right: 0px;
}

.showSearch input::placeholder {
  color: #777777;
}

.closeSearch input {
  width: 0px;
  display: none;
  // animation: closemove .5s;
  // transition-timing-function: ease-out;
  border: unset;
  // transition: .5s;
  border: unset;
}

.closeSearch img {
  display: none;
}


.searchResults {
  max-width: 100%;
  width: 100%;
  height: 250px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
  border: 1px solid #fff;
  background-color: #fff;
  border-radius: 8px;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 999;
  transform: translateY(40px);
  overflow-y: auto;
}


.searchResults::-webkit-scrollbar {
  width: 8px;
  height: 6px;
}

/* Track */
.searchResults::-webkit-scrollbar-track {
  background: #e5e7eb;
  border-radius: 10px;
}

/* Handle */
.searchResults::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}



.SingleSearchDiv {
  word-break: break-all;
  padding: 12px 16px;
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
  margin: 0px;
  color: #777777;
  border-bottom: 1px solid #F5F5F5;
  cursor: pointer;
}

.SingleSearchDiv:hover {
  background: #F5F5F5;
}


.SingleSearchDiv div:last-child {
  margin-bottom: 0px;
}

.SingleSearchDiv:hover {
  background: #f8f8f8;
}









.sitemap {
  padding: 80px 0px;
}

.min_height205 {
  min-height: 205px;
}

.min_height212 {
  min-height: 212px;
}

.sitemap_inlist li {
  list-style: none;
}

.text_nowrap {
  text-wrap: nowrap;
}

.font_Size10 {
  font-size: 10px;
}

.clearco-job-listing {
  height: 300px;
  overflow: auto;
}



.popupHeight {
  max-height: 400px;
  overflow: auto;
}

#myModal {
  ul {
    list-style-image: url('https://theecode-assets.s3.us-west-1.amazonaws.com/Crm-Image/default-image/theecode-pli-imgs/list_arrow.svg');
  }
}

.sectionheaders h2 {
  background: #ffffffb8 !important;
}

.uploadLableItem{
  background: #F5F5F5;
    border-radius: 7px;
    padding: 8px;
    font-size: 14px;
    font-weight: 500;
    display: inline-flex;
    gap: 11px;
    align-items: center;
    margin-right: 12px;
  }
  .removeUploadItem{
        background-color: #f0f0f0f0;
        border: 1px solid #f0f0f0f0;
        font-size: 14px;
        font-weight: 600;
        border-radius: 5px;
        width: 20px;
        height: 20px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 0px 5px;
        color: #ff4b00;
  }
